.dateline {
    display: inline-block;
    margin: 0;
    padding-left: 18px;
    font-size: 12px;
    line-height: 1;
    font-weight: map-get($font-weight, semibold);
    text-transform: uppercase;
    letter-spacing: .1em;
    border-left: 2px solid map-get($color, primary);

    @include screen(medium) {
        font-size: 14px;
    }
}
