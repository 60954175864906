$this: '.content-text';

#{$this} {
    figure {
        margin: 40px 0;
    }

    body.layout--text & {
        h2 {
            margin-top: 2.2em;
            font-size: 30px;
            font-weight: map-get($font-weight, bold);
        }

        h3 {
            margin-top: 2.2em;
            font-weight: map-get($font-weight, semibold);
            letter-spacing: 0;
        }
    }

    a {
        color: map-get($color, primary);
        font-weight: map-get($font-weight, semibold);

        &:hover {
            text-decoration: underline;
        }
    }

    p {
        margin: 2em 0;
    }

    li {
        margin: 16px 0;
    }

    &.claim {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-top: 120px !important;
        padding-left: 60px;
        padding-right: 60px;
        font: italic 50px/1 Tinos, serif;
        text-align: center;
        color: map-get($color, claim);

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: -1000em;
            right: -1000em;
            z-index: -1;
            height: 5px;
            background: map-get($color, claim);
        }

        p {
            display: inline-block;
            max-width: 760px;
            margin: 0;
            padding: 0 30px;
            background: map-get($color, background);
        }

        @include screen(medium) {
            font-size: 80px;
        }
    }
}

@include screen(medium, $this) {
    &.claim {
        margin-top: 200px !important;
    }
}

@include screen(large, $this) {
    &.media--right {
        position: relative;

        figure {
            position: absolute;
            top: 50%;
            right: 0;
            width: 680px;
            margin: 0;
            transform: translate(calc(-100% / 12 * -1), -50%);
        }

        h1, h2, h3, p {
            max-width: calc(100% / 12 * 5);
        }
    }
}
