.content-topics {
    &__navigation {
        nav {
            padding-left: 40px;
        }

        a, button {
            display: block;
            padding: 10px 0;
            color: map-get($color, primary);
            background: none;
            border: none;
            cursor: pointer;
        }

        &--desktop {
            display: none;
        }
    }

    &__item {
        margin-top: 75px;
    }

    &__title {
        font-weight: map-get($font-weight, semibold);
    }

    &__text {
        font-size: 16px;
        letter-spacing: 0;
    }

    &__link {
        @include link();
        @include hover();
    }
}

@include screen(medium, '.content-topics') {
    @include grid-row;

    &__header {
        @include grid-col(5);
    }

    &__main {
        @include grid-col(12);
        @include grid-row;
    }

    &__navigation {
        @include grid-col(5);

        &--mobile {
            display: none;
        }

        &--desktop {
            display: block;
        }

        button {
            position: relative;
            padding-left: 0;
            transition: padding-left .2s ease;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 19px;
                width: 0;
                height: 3px;
                background: map-get($color, primary);
                transition: width .2s ease;
            }

            &:hover,
            &.tns-nav-active {
                padding-left: 40px;

                &:before {
                    width: 28px;
                }
            }
        }
    }

    &__items {
        position: relative;
        @include grid-col(5);
        @include grid-offset(1);

        &::before {
            content: "";
            position: absolute;
            inset: -100px 0 -100px -150px;
            background: url("../../images/cloud.svg") center center/contain no-repeat;
        }
    }

    &__item {
        margin-top: 0;
    }

    &__title {
        margin-top: 0;
        font-weight: map-get($font-weight, light);
    }

    &__link {
        margin-top: 40px;
    }
}
