$this: '.news-client';

#{$this} {
    &__headline {
        hyphens: auto;
    }

    &__contact {
        margin-top: 25px;

        &-headline {
            margin-top: 0;
        }

        p {
            margin: 0;
        }

        a {
            color: map-get($color, primary);
            font-weight: map-get($font-weight, semibold);

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__cols {
        margin-bottom: 75px;
    }

    &__articles-headline,
    &__gallery {
        margin: 75px 0 2em;
    }

    &__gallery {
        font-size: 14px;

        &-items {
            @include grid-row();
            margin-top: 1em;
        }

        &-item {
            margin-bottom: 15px;
        }

        &-download {
            display: block;
            margin: 15px 0;
            padding-left: 30px;
            background: url("../../images/download.svg") 0 0/18px no-repeat;
        }

        img {
            width: 100%;
        }

        figcaption {
            display: none;
        }
    }
}

@include screen(480, $this) {
    &__gallery {
        &-item {
            @include grid-col(6);
        }
    }
}

@include screen(medium, $this) {
    &__headline {
        hyphens: manual;
    }

    &__cols {
        @include grid-row();
    }

    &__main {
        @include grid-col(6);
        padding-right: 50px;
        hyphens: auto;
    }

    &__gallery {
        margin-top: 0;
        @include grid-col(6);
    }

    &__articles {
        &-headline {
            display: flex;
            justify-content: center;
        }
    }
}
