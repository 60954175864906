.content-clients {
    margin: 70px 0;

    &__controls {
        display: flex;
        align-items: flex-end;
        margin-bottom: 35px;
    }

    &__headline {
        margin-bottom: 0;
    }

    figure {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 150px;
        height: 120px;
        margin: 0 auto;
        padding: 20px;

        img {
            max-height: 100%;
        }
    }
}

@include screen(medium, '.content-clients') {
    @include grid-row;

    &__header {
        @include grid-col(4);
    }

    &__controls {
        flex-direction: column;
    }

    &__headline {
        margin-right: 40px;
    }

    &__main {
        @include grid-col(8);

        figure {
            width: 100%;
            margin: 0;
            padding: 20px 0;
        }
    }
}
