$grid-gutter: 15px !default;
$max-width: 1200px !default;

@mixin wrapper() {
    padding: 0 $grid-gutter;

    @include screen(medium) {
        max-width: $max-width;
        margin: 0 auto;
        padding: 0 2*$grid-gutter;
    }
}

@mixin grid-row() {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$grid-gutter;
    margin-right: -$grid-gutter;

    & > * {
        padding-left: $grid-gutter;
        padding-right: $grid-gutter;
    }
}

@mixin grid-col($cols: 12, $of: 12) {
    padding-left: $grid-gutter;
    padding-right: $grid-gutter;
    width: percentage(calc(1 / $of) * $cols);
}

@mixin grid-offset($cols, $of: 12) {
    margin-left: percentage(calc(1 / $of) * $cols);
}

.row {
    @include grid-row;

    &--center {
        justify-content: center;
        text-align: center;
    }

    &--middle {
        align-items: center;
    }

    &--bottom {
        align-items: flex-end;
    }

    @include screen(mobile-only) {
        &--gap-medium {
            gap: 30px;
        }

        &--gap-large {
            gap: 60px;
        }
    }

    @include screen(medium, max-width) {
        &--reverse-mobile {
            flex-direction: column-reverse;
        }
    }
}

.col {
    padding-left: $grid-gutter;
    padding-right: $grid-gutter;
    width: 100%;

    &--center {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
    }

    &--right {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
    }
}

@for $col from 1 to 13 {
    .col-#{$col} {
        @include grid-col($col);
    }

    .offset-#{$col} {
        @include grid-offset($col);
    }
}

@include screen(medium) {
    @for $col from 1 to 13 {
        .col-md-#{$col} {
            @include grid-col($col);
        }

        .offset-md-#{$col} {
            @include grid-offset($col);
        }
    }
}

@include screen(large) {
    @for $col from 1 to 13 {
        .col-lg-#{$col} {
            @include grid-col($col);
        }

        .offset-lg-#{$col} {
            @include grid-offset($col);
        }
    }
}
