figcaption {
    padding: 10px 0;
    font-size: 14px;
    line-height: 18px;
    color: map-get($color, primary);
}

a[data-lightbox-target="element"] {
    display: block;
    position: relative;

    &::before {
        content: "";
        display: block;
        position: absolute;
        inset: 0;
        background: url("../../images/zoom.svg") 50% 50%/38px 38px no-repeat;
        z-index: 10;
        pointer-events: none;
    }
}

.section-header__inside,
#main .mod_article > .inside,
.section-footer__inside {
    position: relative;
    padding-left: 20px;
    padding-right: 20px;

    @include screen(medium) {
        max-width: 1200px;
        margin: 0 auto;
        padding-left: $grid-gutter;
        padding-right: $grid-gutter;
    }
}

.mod_article.pattern {
    @include clearfix(before);
    @include clearfix(after);
    background: map-get($color, pattern) url("../../images/pattern.png") 0 0/200px 200px repeat;
}

.mod_article.pattern-accent {
    overflow: hidden;
    background: map-get($color, accent) url("../../images/pattern.png") 0 0/200px 200px repeat;
}

.mod_article.services {
    overflow: hidden;
    margin-top: -130px;
    padding-top: 60px;
    background: map-get($color, accent) url("../../images/pattern.png") 0 0/200px 200px repeat;

    @include screen(medium) {
        margin-top: -230px;
        padding-top: 160px;
    }
}

.mod_article.overflow-hidden {
    overflow: hidden;
}

.mod_article {
    font-size: 18px;
    line-height: 1.55;
    letter-spacing: .05em;

    h2 {
        font-size: 36px;
        line-height: 1.2;
        letter-spacing: 0;

        @include screen(medium) {
            font-size: 48px;
            letter-spacing: .05em;
        }
    }

    h3 {
        font-size: 24px;
        line-height: 1.2;
        font-weight: map-get($font-weight, regular);
    }

    .dateline + h2 {
        margin: 15px 0 30px;

        @include screen(medium) {
            margin: 35px 0 50px;
        }
    }

    > .inside {
        > .ce_rsce_cta,
        > .ce_rsce_services-list,
        > .ce_rsce_services-more,
        > .ce_rsce_dateline:first-child,
        > .ce_rsce_grid-row--start:first-child,
        > .content-text:first-child {
            margin-top: 70px;

            @include screen(medium) {
                margin-top: 100px;
            }
        }

        > .ce_rsce_cta,
        > .ce_rsce_services-list,
        > .ce_rsce_services-more,
        > .ce_rsce_team,
        > .ce_rsce_grid-row--start:last-child,
        > .content-text:last-child {
            margin-bottom: 70px;

            @include screen(medium) {
                margin-bottom: 100px;
            }
        }
    }
}
