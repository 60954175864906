.section-footer {
    background: map-get($color, footer);
    color: map-get($color, footer-text);
    text-align: center;

    &__inside {
        padding: 50px 0;
    }

    a {
        text-decoration: underline;
        transition: color .1s ease;

        &:hover {
            color: map-get($color, footer-hover);
            text-decoration: underline;
        }
    }

    &__navigation {
        font-size: 18px;
        letter-spacing: 0.05em;

        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 300px;
            margin: 0 auto;
        }

        li {
            padding-bottom: 10px;

            &:nth-child(even)::before {
                padding: 0 20px;
                content: ".";
            }
        }

        a {
            color: map-get($color, footer-links);
            text-decoration: none;
        }
    }

    .social-media {
        margin: 30px;

        a {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    .content-hyperlink {
        margin: 35px 20px;
        text-align: center;

        img {
            max-width: 400px;
        }
    }

    .content-text,
    &__legal {
        font-size: 12px;
        line-height: 2;
        color: map-get($color, footer-text);

        p {
            margin: 0;
        }

        a {
            color: inherit;
            transition: color .1s ease;

            &:hover {
                color: map-get($color, footer-hover);
            }
        }
    }

    &__legal {
        margin-top: 30px;
        font-size: 10px;

        ul {
            display: flex;
            justify-content: center;
        }

        li {
            &::before {
                padding: 0 10px;
                content: "|";
            }

            &:first-child::before {
                content: none;
            }
        }
    }
}

@include screen(medium, '.section-footer') {
    &__navigation {
        ul {
            width: auto;
        }

        li {
            padding-bottom: 10px;

            &::before {
                padding: 0 20px;
                content: ".";
            }

            &:first-child::before {
                content: none;
            }
        }
    }

    .rte {
        display: flex;
        justify-content: center;

        p {
            &::before {
                content: "|";
                padding: 0 15px;
            }

            &:first-child::before {
                content: none;
            }
        }
    }
}
