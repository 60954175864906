.tns-outer {
    display: flex;
    flex-direction: column-reverse;
}

.tns-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;

    button {
        margin: 0 5px;
        width: 5px;
        height: 5px;
        background: map-get($color, primary);
        border: none;
        border-radius: 50%;

        &.tns-nav-active {
            background: #8a8e8d;
        }

        @include screen(medium) {
            margin: 0 10px;
            width: 10px;
            height: 10px;
        }
    }
}

.tns-controls,
.slider__controls {
    display: flex;
    gap: 12px;

    .slider__control,
    button {
        position: relative;
        display: block;
        width: 50px;
        height: 50px;
        background: none;
        border: 1px solid map-get($color, primary);
        border-radius: 50%;
        text-indent: -999em;
        cursor: pointer;
        transition: opacity .1s ease;

        &::before {
            content: "";
            position: absolute;
            top: 17px;
            width: 15px;
            height: 15px;
            border-left: 2px solid map-get($color, primary);
            border-top: 2px solid map-get($color, primary);
            border-radius: 2px;
            pointer-events: none;
        }

        &--prev::before,
        &[data-controls="prev"]::before {
            left: 19px;
            transform: rotateZ(-45deg);
        }

        &--next::before,
        &[data-controls="next"]::before {
            right: 19px;
            transform: rotateZ(135deg);
        }

        &:hover {
            background: map-get($color, primary);

            &::before {
                border-color: #fff;
            }
        }

        &:disabled {
            opacity: .5;
            cursor: auto;

            &:hover {
                background: inherit;
            }

            &::before {
                border-color: inherit;
            }
        }
    }
}
