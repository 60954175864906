$body-font: 'Montserrat, sans-serif';

$color: (
    primary: #92bce8,
    secondary: #72614c,
    accent: #fddd65,
    navigation: #73624d,
    background: #eff2f5,
    pattern: #dce7f2,
    text: #2d2d2d,
    claim: #dddddd,
    contact: #e6e7e9,
    footer: #72614c,
    footer-text: #a99174,
    footer-links: #ddbe98,
    footer-hover: #fddd65,
);

@mixin screen($size, $block: null) {
    $sizes: (
        mobile-only: "max-width: 767px",
        medium: "min-width: 768px",
        //tablet-only: "min-width: 768px) and (max-width: 1219px",
        large: "min-width: 1220px",
    );

    @if (map-has_key($sizes, $size)) {
        $size: map-get($sizes, $size);
    } @else if (type_of($size) == 'number') {
        $size: "min-width: #{$size}px";
    }

    @media screen and (#{unquote($size)}) {
        @if ($block) {
            #{$block} {
                @content;
            }
        } @else {
            @content;
        }
    }
}

@mixin link($padding: 30) {
    position: relative;
    display: inline-block;
    color: map-get($color, primary);
    font-size: 14px;
    line-height: 1.3;
    font-weight: map-get($font-weight, semibold);
    text-transform: uppercase;
    letter-spacing: 0.1em;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: calc(($padding * -1px) - 14px);
        width: 14px;
        height: 100%;
        background: url("../images/arrow.svg") 50% 50%/contain no-repeat;
    }
}

@mixin hover($colorName: accent, $padding: 30px) {
    background-image: linear-gradient(transparent 4px, map-get($color, $colorName) 4px, map-get($color, $colorName) calc(100% - 10px));
    background-repeat: no-repeat;
    background-size: calc(100% - $padding) 333%;
    transition: all .3s;
    background-position: 100% calc(1em - 10px);
    padding-right: $padding;
    box-decoration-break: clone;

    &.active,
    &:hover {
        background-size: 100% 100%;
        background-position: calc(100% - $padding) 0;
    }
}

@mixin clearfix($prop: after) {
    &:#{$prop} {
        display: table;
        clear: both;
        content: "";
    }
}
