.pagination {
    ul {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 70px 0;
        padding: 0;
        list-style: none;
    }

    &__control {
        flex-shrink: 0;
        position: relative;
        display: block;
        width: 50px;
        height: 50px;
        margin: 0 15px;
        background: none;
        border: 1px solid map-get($color, primary);
        border-radius: 50%;
        transition: opacity .1s ease;

        &::before {
            content: "";
            position: absolute;
            top: 17px;
            width: 15px;
            height: 15px;
            border-left: 2px solid map-get($color, primary);
            border-top: 2px solid map-get($color, primary);
            border-radius: 2px;
            pointer-events: none;
        }

        &--prev::before {
            left: 19px;
            transform: rotateZ(-45deg);
        }

        &--next::before {
            right: 19px;
            transform: rotateZ(135deg);
        }

        &--disabled {
            opacity: .5;
        }

        a, span {
            display: block;
            width: 100%;
            height: 100%;
            padding: 15px 35px;
            text-indent: -999em;
        }
    }

    &__link {
        display: flex;
        align-items: baseline;

        a, strong {
            display: block;
            padding: 15px;
            font-weight: map-get($font-weight, light);
        }

        a {
            color: map-get($color, primary);

            &:hover {
                text-decoration: underline;
            }
        }

        &::after {
            content: ".";
            color: map-get($color, primary);
        }

        &:nth-child(6n)::after {
            content: none;
        }
    }
}

@include screen(medium, '.pagination') {
    &__link {
        a, strong {
            padding-left: 35px;
            padding-right: 35px;
        }
    }
}
