$this: '.news-home';

#{$this} {
    position: relative;

    .tns-ovh {
        overflow: visible !important;
    }

    &__controls {
        display: flex;
        align-items: flex-end;
        margin-bottom: 35px;

        h2 {
            margin-bottom: 0;
        }
    }

    &__items {
        margin: 0 -20px;
        padding-left: 20px;
    }

    &__item {
        position: relative;

        &-poster {
            background: url("../../images/news.png") center center/cover no-repeat;

            img {
                width: 100%;
            }
        }

        &-content {
            position: absolute;
            inset: 0 25px 0 0;
            display: flex;
            flex-direction: column;
            color: #fff;
            letter-spacing: 0.05em;
            background: rgba(0,0,0,.3);
            pointer-events: none;

            > * {
                pointer-events: all;
            }
        }

        &-headline {
            margin: 0;
            padding: 25px;
            font-size: 18px;
            line-height: 1.3;
            font-weight: map-get($font-weight, semibold);
            text-transform: uppercase;
            flex-grow: 1;
        }

        &-client {
            display: block;
            padding: 0 25px;
            font-weight: map-get($font-weight, bold);
        }

        &-date {
            display: block;
            padding: 0 25px 25px;
        }
    }
}

@include screen(medium, $this) {
    @include grid-row();

    .tns-ovh {
        overflow: hidden !important;
    }

    &__header {
        @include grid-col(3);
        flex-shrink: 0;
        align-self: center;
    }

    &__controls {
        flex-direction: column;

        h2 {
            margin-bottom: 10px;
        }
    }

    &__main {
        @include grid-col(9);
    }

    &__item {
        &-headline {
            font-size: 24px;
        }

        &-client,
        &-date {
            font-size: 18px;
        }
    }
}

.mod_article.newsroom {
    margin-bottom: -50px;

    + .mod_article {
        padding-top: 50px;
    }

    @include screen(medium) {
        margin-bottom: -70px;

        + .mod_article {
            padding-top: 70px;
        }
    }
}
