$body-font: 'Arial, sans-serif' !default;

$font-weight: (
    thin: 100,
    xlight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    xbold: 800,
    black: 900,
) !default;

html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
    html {
        scroll-behavior: auto;
    }
}

*, *:before, *:after {
    box-sizing: inherit;
}

body {
    margin: 0;
    padding: 0;
    -webkit-text-size-adjust: none;
}

input,
button,
textarea,
select {
    margin: 0;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    font-size: 99%;
    border-radius: 0;
}

textarea,
button,
input[type="email"],
input[type="tel"],
input[type="text"],
input[type="submit"],
input[type="search"] {
    -webkit-appearance: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
    display: none;
}

textarea[disabled],
button[disabled],
input[type="email"][disabled],
input[type="tel"][disabled],
input[type="text"][disabled],
input[type="submit"][disabled],
input[type="search"][disabled] {
    cursor: not-allowed;
}

select[disabled],
button[disabled],
input[disabled],
input[type="checkbox"][disabled] + label,
input[type="radio"][disabled] + label {
    cursor: not-allowed;
}

table {
    //width: 100%;
    font-size: inherit;
    border-collapse: collapse;
    border-spacing: 0;
}

table td, table th {
    padding: 4px 15px;
    vertical-align: top;
}

tr > :first-child {
    padding-left: 0;
}

th {
    font-weight: map-get($font-weight, medium);
}

caption,
strong,
b {
    font-weight: map-get($font-weight, bold);
}

ul ul {
    margin-bottom: 0;
}

form,
figure,
blockquote {
    margin: 0;
    padding: 0;
}

img,
iframe {
    max-width: 100%;
    height: auto;
    border: none;
    vertical-align: middle;
}

header,
footer,
nav,
section,
main,
aside,
article,
figure,
figcaption,
turbo-frame {
    display: block;
}

html {
    font-size: 16px;
}

body {
    line-height: 1.625;
    font-family: unquote($body-font);
    font-size: 16px;
    font-weight: map-get($font-weight, light);
    color: map-get($color, text);
}

h1, h2, h3, h4, h5, h6 {
    font-weight: map-get($font-weight, light);
}

a {
    color: inherit;
    text-decoration: none;
}

caption,
th,
td {
    //noinspection CssOverwrittenProperties
    text-align: left;
    //noinspection CssOverwrittenProperties
    text-align: start;
}

abbr,
acronym {
    font-variant: normal;
    border-bottom: 1px dotted map-get($color, text);
    cursor: help;
}

blockquote,
q {
    quotes: none;
}

sup {
    vertical-align: text-top;
}

sub {
    vertical-align: text-bottom;
}

del {
    text-decoration: line-through;
}

ins {
    text-decoration: none;
}

pre,
code {
    font-family: monospace;
}

/* Vertical align */
.float_left {
    float: left;
}

.float_right {
    float: right;
}

/* Clear floats */
.clear,
#clear {
    height: 0.1px;
    font-size: 0.1px;
    line-height: 0.1px;
    clear: both;
}

/* Hide invisible elements */
.invisible,
.tns-visually-hidden {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
}

.handorgel .accordion {
    display: none;
    overflow: hidden;
    height: 0;
    transition: height 0.1s ease 0.1s;

    &.open {
        display: block;
        transition: height 0.2s ease;
    }

    &.active {
        overflow: visible;
    }
}

.nav-list,
.mod_navigation,
.mod_changelanguage,
.mod_breadcrumb {
    ul, li {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
}

.ce_download,
.ce_downloads {
    .size {
        display: none;
    }
}
