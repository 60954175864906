.ce_rsce_cta {
    overflow: hidden;
    flex-direction: column-reverse;

    figure {
        display: block;

        img {
            width: auto;
            max-width: none;
            height: 380px;
        }
    }

    &__headline {
        margin: 0 0 30px;
        font-family: Ashby, sans-serif;
        font-weight: map-get($font-weight, xbold);
        font-size: 30px;
        line-height: 1;
        text-transform: uppercase;

        span {
            display: block;
            color: map-get($color, primary);

            &:first-child {
                color: map-get($color, secondary);
            }
        }
    }

    &__text {
        margin: 0 0 40px;

        br {
            display: none;

            @include screen(medium) {
                display: initial;
            }
        }
    }

    &__link {
        margin: 0;
        @include link(10);
        @include hover(accent);
    }

    &--layout-home  {
        overflow: visible;

        figure {
            margin: 50px -15px 0 20px;
        }

        .ce_rsce_cta {
            &__headline {
                font-size: 65px;
            }
        }
    }
}

@include screen(medium, '.ce_rsce_cta') {
    flex-direction: row;

    &__headline {
        width: calc(100% / 11 * 9);
        font-size: 60px;
    }

    &--layout-home {
        margin-bottom: 180px !important;

        figure {
            position: absolute;
            top: -40px;
            left: calc(100% / 12 * 8.2);
            z-index: -1;
            margin: 0;

            img {
                height: 530px;
            }
        }

        .ce_rsce_cta {
            &__headline {
                font-size: 100px;
            }

            &__text {
                width: calc(100% / 11 * 5);
                font-size: 24px;
                line-height: 1.5;
            }
        }
    }
}
