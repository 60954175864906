$this: '.news-reader';

#{$this} {
    margin-bottom: 70px;

    &__headline {
        hyphens: auto;
    }

    &__poster {
        margin-left: -20px;
        margin-right: -20px;
        background: url("../../images/news.png") center center/cover no-repeat;

        img {
            width: 100%;
        }

        figcaption {
            display: none;
        }
    }

    &__downloads {
        display: flex;
        justify-content: space-around;
        margin-left: -20px;
        margin-right: -20px;
        padding: 20px;
        background: map-get($color, accent) url("../../images/pattern.png") 0 0/200px 200px repeat;
    }

    &__download {
        display: block;
        padding: 38px 10px 0;
        color: map-get($color, primary);
        font-size: 12px;
        line-height: 1.4;
        text-align: center;
        letter-spacing: 0.08em;
        font-weight: map-get($font-weight, bold);

        &--pdf {
            background: url("../../images/pdf.svg") 50% 0/auto 25px no-repeat;
        }

        &--images {
            background: url("../../images/images.svg") 50% 0/auto 22px no-repeat;
        }
    }

    &__main {
        padding: 40px 0;

        .content-text {
            margin-bottom: 3em;
        }

        h2 {
            font-size: 24px;
            font-weight: map-get($font-weight, semibold);
        }

        .content-hyperlink {
            margin-bottom: 80px;

            a {
                color: map-get($color, primary);
                font-weight: map-get($font-weight, semibold);
                @include hover;
            }
        }
    }

    &__intro {
        font-weight: map-get($font-weight, bold);
    }

    &__download-headline {
        margin-bottom: 30px;
    }

    &__files {
        margin-bottom: 75px;
        font-size: 14px;
        letter-spacing: 0.08em;

        ul {
            list-style: none;
            margin: 0;
            padding: 10px 30px;
        }

        a {
            display: block;
            padding-left: 30px;
            background: url("../../images/pdf.svg") 0 0/20px no-repeat;
            line-height: 30px;
            font-weight: map-get($font-weight, semibold);
            color: map-get($color, primary)
        }
    }

    &__gallery {
        .tns-ovh {
            position: relative;
            overflow: visible !important;

            &::before,
            &::after {
                content: "";
                position: absolute;
                top: 0;
                width: 20px;
                height: 100%;
                background: #eff2f5;
                opacity: .9;
                z-index: 1;
            }

            &::before {
                right: 100%;
            }

            &::after {
                left: 100%;
            }
        }

        .tns-horizontal {
            display: flex;
        }

        figure {
            background: url("../../images/news.png") center center/cover no-repeat;
        }

        img {
            width: auto;
            max-width: none;
            height: 180px;
        }

        &-download {
            display: block;
            margin: 15px 0;
            padding-left: 30px;
            font-size: 16px;
            background: url("../../images/download.svg") 0 4px/18px no-repeat;
        }
    }

    &__footer {
        margin-bottom: 75px;
        padding: 40px 20px 20px;
        background: #e6e7e9;

        h3 {
            font-weight: map-get($font-weight, semibold);
        }
    }

    &__contact {
        margin-bottom: 25px;

        &-headline {
            margin-top: 0;
        }

        p {
            margin: 0;
        }

        a {
            color: map-get($color, primary);
            font-weight: map-get($font-weight, semibold);

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

@include screen(medium, $this) {
    margin-bottom: 100px;

    &__headline {
        hyphens: manual;
    }

    &__downloads {
        justify-content: center;
        gap: 90px;
    }

    &__download {
        padding: 10px;
        background-position: 0 50%;
        font-size: 16px;

        &--pdf {
            padding-left: 40px;
            background-size: 20px auto;
        }

        &--images {
            padding-left: 50px;
            background-size: 30px auto;
        }
    }

    &__main {
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 100px;
    }

    &__footer {
        padding: 60px 0 40px;
        max-width: 970px;
        margin-left: auto;
        margin-right: auto;
    }

    &__contacts {
        display: flex;
        flex-wrap: wrap;
        max-width: 800px;
        margin: 0 auto;
    }

    &__contact {
        width: 50%;

        &-headline {
            width: 100%;
        }

        a {
            font-size: 16px;
        }
    }

    &__files {
        font-size: 16px;
    }

    &__gallery {
        img {
            height: 360px;
        }

        figcaption {
            display: none;
        }

        .tns-ovh {
            &::before,
            &::after {
                width: 15px;
            }
        }

        .tns-nav {
            position: relative;
            z-index: 10;
            width: 300px;
            margin: 30px auto 0;
        }

        .tns-controls {
            position: relative;
            z-index: 8;
            top: -50px;
            justify-content: space-between;
            width: 430px;
            margin: 0 auto;
        }
    }
}

@include screen(1200, $this) {
    &__gallery {
        .tns-ovh {
            &::before,
            &::after {
                width: 999em;
            }
        }
    }
}
