$this: '.news-clients';

#{$this} {
    &__filters {
        display: flex;
        flex-direction: column;
        gap: 20px;
        position: relative;
        margin: 0 (-$grid-gutter) 30px;
        padding: 30px ($grid-gutter + 20px);

        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            bottom: 0;
            left: $grid-gutter;
            right: $grid-gutter;
            background: #e6e7e9;
        }

        button {
            width: 100%;
            text-align: start;
            background: none;
            border: none;
            cursor: pointer;

            &.current {
                height: 40px;
                padding: 0 20px;
                overflow: hidden;
                font-weight: map-get($font-weight, semibold);
                white-space: nowrap;
                text-overflow: ellipsis;
                background: map-get($color, background) url("../../images/dropdown.svg") 95% 50%/15px 8px no-repeat;
            }
        }
    }

    &__filter {
        @extend .nav-list;
        position: relative;
        font-size: 14px;

        > ul {
            position: absolute;
            width: 100%;
            top: 40px;
            padding-bottom: 10px;
            background: #fff;

            opacity: 0;
            pointer-events: none;
            transition: opacity .1s ease-in-out;

            &.active {
                opacity: 1;
                pointer-events: all;
                z-index: 10;
            }

            a {
                display: block;
                padding: 6px 20px;

                &:hover {
                    color: map-get($color, primary);
                }
            }
        }
    }

    &__search {
        input {
            width: 100%;
            height: 40px;
            padding: 0 20px;
            font-size: 14px;
            line-height: 40px;
            font-weight: map-get($font-weight, semibold);
            background: map-get($color, background) url("../../images/search.svg") 95% 50%/15px 15px no-repeat;
            border: none;

            &:focus {
                background-color: #fff;
            }

            &::placeholder {
                font-weight: map-get($font-weight, light);
                font-style: italic;
            }
        }
    }

    &__filters {
        @each $source, $target in ('clients': 'categories', 'categories': 'clients') {
            &--#{$source}-active {
                #{$this}__filter--#{$source} {
                    button.current {
                        background: #fff;
                    }
                }

                #{$this}__filter--#{$target},
                #{$this}__search {
                    button.current,
                    input {
                        color: #c9cacc;
                        background: #e8e9eb;
                    }
                }
            }
        }
    }

    &__empty {
        flex-grow: 1;
        margin-bottom: 70px;
        text-align: center;
    }

    &__items {
    }

    &__item {
        margin: 0 0 70px;
        line-height: 1.25;

        &-poster {
            background: url("../../images/news.png") center center/cover no-repeat;

            img {
                width: 100%;
            }
        }

        &-headline {
            margin: 0;

            a {
                display: block;
                padding: 1em;
                font-size: 20px;
                font-weight: map-get($font-weight, semibold);
                letter-spacing: 0.08em;
                text-align: center;
                hyphens: auto;
                background: #fff;
            }
        }

        &-client {
            display: block;
            margin: .25em 0;
            font-weight: map-get($font-weight, bold);
            letter-spacing: 0.05em;
        }

        &-date {
            display: block;
            margin: .25em 0;
            letter-spacing: 0.05em;
        }
    }
}

@include screen(medium, $this) {
    margin-bottom: 100px;

    &__filters {
        flex-direction: row;
        gap: 0;
        position: relative;
        padding-left: 0;
        padding-right: 0;

    }

    &__filter {
        @include grid-col(4);
        @include grid-offset(2);

        > ul {
            width: calc(100% - 2 * $grid-gutter);
        }
    }

    &__search {
        @include grid-col(4);
    }

    &__items {
        @include grid-row();
    }

    &__item {
        @include grid-col(4);
    }
}
