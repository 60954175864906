.ce_rsce_services-list,
.ce_rsce_services-more,
.ce_rsce_services-details {
    position: relative;
    margin: 0 -20px;
    @extend .nav-list;

    @include screen(medium) {
        margin: 0 $grid-gutter * -1;

        &:before {
            left: $grid-gutter;
            right: $grid-gutter;
        }
    }
}

.ce_rsce_services-list,
.ce_rsce_services-more {
    position: relative;
    margin-left: 0;
    margin-right: 0;
    padding: 50px 20px 50px 40px;
    background: map-get($color, accent) url("../../images/pattern.png") 0 0/200px 200px repeat;

    li {
        padding: 15px 20px 15px 40px;
        font-size: 24px;
    }

    a {
        @include hover(primary);
    }
}

@include screen(medium, '.ce_rsce_services-list') {
    padding: 50px 0;

    header {
        @include grid-col(6);
        @include grid-offset(1);
    }

    ul {
        display: flex;
        flex-wrap: wrap;
    }

    li {
        @include grid-col(2);
        @include grid-offset(1);

        &:first-child,
        &:nth-child(3n+1) {
            @include grid-offset(2);
        }
    }
}

@include screen(medium, '.ce_rsce_services-more') {
    padding: 60px $grid-gutter 80px;

    header {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    ul {
        display: flex;
        justify-content: space-between;
    }

    li {
        padding-left: 10px;
        padding-right: 40px;
    }
}

.ce_rsce_services-details {
    padding-bottom: 40px;

    &__item {
        margin-top: 40px;
        margin-left: 40px;
        font-size: 18px;
        line-height: 28px;
    }

    &__headline {
        a {
            @include hover(primary);
        }
    }

    &__link {
        display: inline-block;
        @include link();
    }
}

@include screen(medium, '.ce_rsce_services-details') {
    &__item {
        margin-bottom: 40px;
    }

    &__headline {
        margin-bottom: 34px;
    }

    &__link {
        margin-top: 25px;
    }
}
