.section-header {
    &__logo {
        display: block;
        width: 170px;
        height: 75px;
        background: url("../../images/logo.svg") 0 50% no-repeat;
    }

    &__claim {
        display: none;
    }

    &__toggle {
        position: absolute;
        right: 20px;
        top: 30px;
        z-index: 100;
        height: 3em;
        width: 3em;
        font-size: 12px;
        background: none;
        border: none;
        cursor: pointer;
        transition: .2s all;
        -webkit-tap-highlight-color: transparent;

        &:after {
            content: '';
            display: block;
            position: absolute;
            height: 150%;
            width: 150%;
            top: -25%;
            left: -25%;
        }

        > span {
            top: 50%;
            margin-top: -0.125em;

            &,
            &:after,
            &:before {
                pointer-events: none;
                display: block;
                content: '';
                width: 100%;
                border-radius: 0.25em;
                background-color: map-get($color, primary);
                height: 0.25em;
                position: absolute;
                transform: rotate(0);
                transition: all .2s;
            }

            &:after {
                left: 0;
                top: -1em;
            }

            &:before {
                left: 1em;
                top: 1em;
            }

            &:after,
            &:before {
                width: 2em;
            }
        }

        body.has-overlay & {
            > span {
                & {
                    transform: translateX(-2em);
                    background-color: transparent;
                }

                &:before,
                &:after {
                    left: 0.5em;
                    top: 0;
                }

                &:before {
                    transform: translateX(2em) rotate(135deg);
                }

                &:after {
                    transform: translateX(2em) rotate(-135deg);
                }
            }
        }
    }

    &__overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 99;
        overflow-y: auto;
        background: map-get($color, accent) url("../../images/pattern.png") 0 0/200px 200px repeat;
        opacity: 0;
        pointer-events: none;
        transition: opacity .3s ease-in-out;

        body.has-overlay & {
            opacity: 1;
            pointer-events: all;
        }
    }

    &__language {
        margin-top: 35px;
        text-align: center;
        font-size: 18px;

        ul {
            display: flex;
            justify-content: center;
        }

        li {
            &::before {
                padding: 0 25px;
                content: ".";
            }

            &:first-child::before {
                content: none;
            }
        }

        a {
            color: map-get($color, navigation);
            transition: color .1s ease-in-out;

            &:hover {
                color: map-get($color, primary) !important;
            }

            &.active {
                font-weight: map-get($font-weight, xbold);
            }
        }
    }

    &__navigation {
        margin-top: 35px;
        text-align: center;

        a {
            display: block;
            padding: 5px 0;
            color: map-get($color, navigation);
            transition: color .1s ease-in-out;

            &.active,
            &:hover {
                color: map-get($color, primary) !important;
            }
        }

        .level_1 > li > a {
            font-family: Ashby, sans-serif;
            font-weight: map-get($font-weight, xbold);
            font-size: 30px;
            text-transform: uppercase;
        }

        .level_2 {
            margin-bottom: 30px;

            > li > a {
                font-size: 24px;
            }
        }
    }
}

body.has-overlay {
    overflow: hidden;
}

@include screen(medium, '.section-header') {
    &__logo {
        height: 100px;
    }

    &__claim {
        display: block;
        position: absolute;
        top: 40px;
        right: 100px;
        color: map-get($color, primary);
        font-weight: map-get($font-weight, semibold);
        text-transform: uppercase;
    }

    &__overlay {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__language {
        margin: 40px 0;
    }

    &__navigation {
        margin: 0;

        .level_1 > li > a {
            padding: 15px 0;
        }

        .level_2 {
            font-weight: map-get($font-weight, light);
        }
    }
}
