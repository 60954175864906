.content-pitch {
    margin: 90px 0 70px;

    &:before {
        content: "";
        position: absolute;
        top: -1.5em;
        display: block;
        width: 57px;
        height: 6px;
        background: map-get($color, primary);
    }

    &__text {
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0.05em;
    }

    &__gallery {
        margin-bottom: -165px;

        .tns-outer {
            margin-right: -20px;
        }
    }

    &__controls {
        justify-content: flex-end;
        margin: 1.5em 0;
    }

    &__slider {
        img {
            width: 100%;
        }
    }
}

@include screen(medium, '.content-pitch') {
    @include grid-row;

    &:before {
        left: $grid-gutter;
    }

    &__text {
        @include grid-col(6);
    }

    &__gallery {
        @include grid-col(6);
        align-self: flex-end;

        .tns-outer {
            margin-right: -$grid-gutter;
        }
    }

    &__controls {
        position: absolute;
        right: calc(50% + $grid-gutter);
        bottom: -200px;
    }
}

@include screen(large, '.content-pitch') {
    &:before {
        top: 1.5em;
        left: 43px;
    }

    &__text {
        @include grid-col(5);
        @include grid-offset(1);
    }

    &__gallery {
        .tns-outer {
            margin-right: 0;
        }
    }
}
