.content-contact {
    margin-top: 60px;
    margin-bottom: 120px;

    &__background {
        margin: 0 -20px;
        padding: 60px 20px;
        background: map-get($color, contact);
    }

    &__headline {
        margin: 0 0 40px;
        font-size: 24px;
        line-height: 1.2;
        font-weight: map-get($font-weight, semibold);
    }

    a {
        font-weight: map-get($font-weight, semibold);
    }

    p.address {
        text-transform: uppercase;
    }

    p.address,
    p.phone,
    p.email,
    p.website {
        font-size: 14px;
        line-height: 24px;
    }
}

@include screen(medium, '.content-contact') {
    @include grid-row;

    &__wrapper {
        @include grid-col(10);
        @include grid-offset(1);
    }

    &__background {
        margin: 0;
        padding-left: 0;
        padding-right: 0;
    }

    &__row {
        @include grid-row;
    }

    &__headline {
        @include grid-col(10, 10);
        @include grid-offset(1, 10);
    }

    &__image {
        @include grid-col(4, 10);
        @include grid-offset(1, 10);
    }

    &__contact {
        @include grid-col(5, 10);
    }
}
