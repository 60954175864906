.social-media {
    ul {
        display: flex;
        justify-content: center;
    }

    a {
        display: block;
        width: 20px;
        height: 20px;
        margin: 40px 15px;
        text-indent: -999em;
        background: url("../../images/social.svg") 0 0/60px 80px no-repeat;

        @each $name, $row in ("twitter": 0, "linkedin": -20px, "facebook": -40px) {
            &.#{$name} {
                background-position: $row 0;

                &:hover {
                    background-position: $row -20px;
                }

                .section-footer & {
                    background-position: $row -40px;

                    &:hover {
                        background-position: $row -60px;
                    }
                }
            }
        }
    }
}
