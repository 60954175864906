.content-accordion {
    margin-bottom: 2em;

    .handorgel {
        &__header {
            &__button {
                position: relative;
                padding: 30px 0 10px 25px;
                font-weight: map-get($font-weight, bold);
                background: none;
                border-bottom: 1px solid map-get($color, text);

                &::before {
                    content: "";
                    position: absolute;
                    top: 34px;
                    left: 5px;
                    display: block;
                    width: 7px;
                    height: 13px;
                    background: url("../../images/accordion.svg") 0 0/contain no-repeat;
                    transition: transform .2s ease;
                }
            }

            &--open {
                .handorgel__header__button {
                    &::before {
                        transform: rotateZ(90deg);
                    }
                }
            }
        }

        &__content {
            border: none;
            background: none;

            &__inner {
                padding: 0 0 0 25px;
            }
        }
    }
}
