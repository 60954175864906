.share {
    display: flex;
    justify-content: space-between;
    max-width: 330px;
    margin: 60px auto 100px;

    &__icon {
        display: block;
        width: 40px;
        height: 40px;
        color: map-get($color, primary);
        transition: color .1s ease;

        &:hover {
            color: map-get($color, accent);
        }
    }

    + .content-text {
        margin-top: -2em;
    }
}
