
@import "defaults";
@import "fonts";
@import "layout";
@import "grid";
@import "blocks";

body {
    background: map-get($color, background);
}

@import "sections/all";
@import "sections/header";
@import "sections/footer";

@import "elements/pagination";
@import "elements/share";
@import "elements/slider";
@import "elements/cta";
@import "elements/intro";
@import "elements/text";
@import "elements/team";
@import "elements/social-media";
@import "elements/services";
@import "elements/clients";
@import "elements/topics";
@import "elements/contact";
@import "elements/pitch";
@import "elements/accordion";

@import "news/list";
@import "news/reader";
@import "news/home";
@import "news/clients";
@import "news/client";
