@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Thin"), local("Montserrat-Thin"), url("../fonts/Montserrat-Thin.woff2") format("woff2"), url("../fonts/Montserrat-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Thin Italic"), local("Montserrat-ThinItalic"), url("../fonts/Montserrat-ThinItalic.woff2") format("woff2"), url("../fonts/Montserrat-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat ExtraLight"), local("Montserrat-ExtraLight"), url("../fonts/Montserrat-ExtraLight.woff2") format("woff2"), url("../fonts/Montserrat-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat ExtraLight Italic"), local("Montserrat-ExtraLightItalic"), url("../fonts/Montserrat-ExtraLightItalic.woff2") format("woff2"), url("../fonts/Montserrat-ExtraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Light"), local("Montserrat-Light"), url("../fonts/Montserrat-Light.woff2") format("woff2"), url("../fonts/Montserrat-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Light Italic"), local("Montserrat-LightItalic"), url("../fonts/Montserrat-LightItalic.woff2") format("woff2"), url("../fonts/Montserrat-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url("../fonts/Montserrat-Regular.woff2") format("woff2"), url("../fonts/Montserrat-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Italic"), local("Montserrat-Italic"), url("../fonts/Montserrat-Italic.woff2") format("woff2"), url("../fonts/Montserrat-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Medium"), local("Montserrat-Medium"), url("../fonts/Montserrat-Medium.woff2") format("woff2"), url("../fonts/Montserrat-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Medium Italic"), local("Montserrat-MediumItalic"), url("../fonts/Montserrat-MediumItalic.woff2") format("woff2"), url("../fonts/Montserrat-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat SemiBold Italic"), local("Montserrat-SemiBoldItalic"), url("../fonts/Montserrat-SemiBoldItalic.woff2") format("woff2"), url("../fonts/Montserrat-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Bold"), local("Montserrat-Bold"), url("../fonts/Montserrat-Bold.woff2") format("woff2"), url("../fonts/Montserrat-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Bold Italic"), local("Montserrat-BoldItalic"), url("../fonts/Montserrat-BoldItalic.woff2") format("woff2"), url("../fonts/Montserrat-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat ExtraBold"), local("Montserrat-ExtraBold"), url("../fonts/Montserrat-ExtraBold.woff2") format("woff2"), url("../fonts/Montserrat-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat ExtraBold Italic"), local("Montserrat-ExtraBoldItalic"), url("../fonts/Montserrat-ExtraBoldItalic.woff2") format("woff2"), url("../fonts/Montserrat-ExtraBoldItalic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Black"), local("Montserrat-Black"), url("../fonts/Montserrat-Black.woff2") format("woff2"), url("../fonts/Montserrat-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Black Italic"), local("Montserrat-BlackItalic"), url("../fonts/Montserrat-BlackItalic.woff2") format("woff2"), url("../fonts/Montserrat-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"), url("../fonts/Montserrat-SemiBold.woff2") format("woff2"), url("../fonts/Montserrat-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Ashby Book";
  src: local("Ashby Book"), local("AshbyBook"), url("../fonts/AshbyBook.woff2") format("woff2"), url("../fonts/AshbyBook.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Ashby Book";
  src: local("Ashby Book Italic"), local("AshbyBook-Italic"), url("../fonts/AshbyBook-Italic.woff2") format("woff2"), url("../fonts/AshbyBook-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Ashby";
  src: local("Ashby Medium"), local("AshbyMedium"), url("../fonts/AshbyMedium.woff2") format("woff2"), url("../fonts/AshbyMedium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Ashby";
  src: local("Ashby Bold Italic"), local("Ashby-BoldItalic"), url("../fonts/Ashby-BoldItalic.woff2") format("woff2"), url("../fonts/Ashby-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Ashby";
  src: local("Ashby Black"), local("AshbyBlack"), url("../fonts/AshbyBlack.woff2") format("woff2"), url("../fonts/AshbyBlack.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Ashby Extra";
  src: local("Ashby Extra Bold Italic"), local("AshbyExtraBold-Italic"), url("../fonts/AshbyExtraBold-Italic.woff2") format("woff2"), url("../fonts/AshbyExtraBold-Italic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "Ashby";
  src: local("Ashby Medium Italic"), local("AshbyMedium-Italic"), url("../fonts/AshbyMedium-Italic.woff2") format("woff2"), url("../fonts/AshbyMedium-Italic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Ashby";
  src: local("Ashby Light"), local("AshbyLight"), url("../fonts/AshbyLight.woff2") format("woff2"), url("../fonts/AshbyLight.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Ashby";
  src: local("Ashby Black Italic"), local("AshbyBlack-Italic"), url("../fonts/AshbyBlack-Italic.woff2") format("woff2"), url("../fonts/AshbyBlack-Italic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Ashby Extra";
  src: local("Ashby Extra Bold"), local("AshbyExtraBold"), url("../fonts/AshbyExtraBold.woff2") format("woff2"), url("../fonts/AshbyExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Ashby";
  src: local("Ashby Light Italic"), local("AshbyLight-Italic"), url("../fonts/AshbyLight-Italic.woff2") format("woff2"), url("../fonts/AshbyLight-Italic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Ashby";
  src: local("Ashby Bold"), local("Ashby-Bold"), url("../fonts/Ashby-Bold.woff2") format("woff2"), url("../fonts/Ashby-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Tinos";
  src: local("Tinos Regular"), local("Tinos-Regular"), url("../fonts/Tinos-Regular.woff2") format("woff2"), url("../fonts/Tinos-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Tinos";
  src: local("Tinos Italic"), local("Tinos-Italic"), url("../fonts/Tinos-Italic.woff2") format("woff2"), url("../fonts/Tinos-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Tinos";
  src: local("Tinos Bold"), local("Tinos-Bold"), url("../fonts/Tinos-Bold.woff2") format("woff2"), url("../fonts/Tinos-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Tinos";
  src: local("Tinos Bold Italic"), local("Tinos-BoldItalic"), url("../fonts/Tinos-BoldItalic.woff2") format("woff2"), url("../fonts/Tinos-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: none;
}

input,
button,
textarea,
select {
  margin: 0;
  padding: 0;
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
  font-size: 99%;
  border-radius: 0;
}

textarea,
button,
input[type=email],
input[type=tel],
input[type=text],
input[type=submit],
input[type=search] {
  -webkit-appearance: none;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button {
  display: none;
}

textarea[disabled],
button[disabled],
input[type=email][disabled],
input[type=tel][disabled],
input[type=text][disabled],
input[type=submit][disabled],
input[type=search][disabled] {
  cursor: not-allowed;
}

select[disabled],
button[disabled],
input[disabled],
input[type=checkbox][disabled] + label,
input[type=radio][disabled] + label {
  cursor: not-allowed;
}

table {
  font-size: inherit;
  border-collapse: collapse;
  border-spacing: 0;
}

table td, table th {
  padding: 4px 15px;
  vertical-align: top;
}

tr > :first-child {
  padding-left: 0;
}

th {
  font-weight: 500;
}

caption,
strong,
b {
  font-weight: 700;
}

ul ul {
  margin-bottom: 0;
}

form,
figure,
blockquote {
  margin: 0;
  padding: 0;
}

img,
iframe {
  max-width: 100%;
  height: auto;
  border: none;
  vertical-align: middle;
}

header,
footer,
nav,
section,
main,
aside,
article,
figure,
figcaption,
turbo-frame {
  display: block;
}

html {
  font-size: 16px;
}

body {
  line-height: 1.625;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #2d2d2d;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
}

a {
  color: inherit;
  text-decoration: none;
}

caption,
th,
td {
  text-align: left;
  text-align: start;
}

abbr,
acronym {
  font-variant: normal;
  border-bottom: 1px dotted #2d2d2d;
  cursor: help;
}

blockquote,
q {
  quotes: none;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

del {
  text-decoration: line-through;
}

ins {
  text-decoration: none;
}

pre,
code {
  font-family: monospace;
}

/* Vertical align */
.float_left {
  float: left;
}

.float_right {
  float: right;
}

/* Clear floats */
.clear,
#clear {
  height: 0.1px;
  font-size: 0.1px;
  line-height: 0.1px;
  clear: both;
}

/* Hide invisible elements */
.invisible,
.tns-visually-hidden {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.handorgel .accordion {
  display: none;
  overflow: hidden;
  height: 0;
  transition: height 0.1s ease 0.1s;
}
.handorgel .accordion.open {
  display: block;
  transition: height 0.2s ease;
}
.handorgel .accordion.active {
  overflow: visible;
}

.nav-list ul, .news-clients__filter ul, .news-list__filter ul, .ce_rsce_services-list ul,
.ce_rsce_services-more ul,
.ce_rsce_services-details ul, .nav-list li, .news-clients__filter li, .news-list__filter li, .ce_rsce_services-list li,
.ce_rsce_services-more li,
.ce_rsce_services-details li,
.mod_navigation ul,
.mod_navigation li,
.mod_changelanguage ul,
.mod_changelanguage li,
.mod_breadcrumb ul,
.mod_breadcrumb li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.ce_download .size,
.ce_downloads .size {
  display: none;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}
.row > * {
  padding-left: 15px;
  padding-right: 15px;
}
.row--center {
  justify-content: center;
  text-align: center;
}
.row--middle {
  align-items: center;
}
.row--bottom {
  align-items: flex-end;
}
@media screen and (max-width: 767px) {
  .row--gap-medium {
    gap: 30px;
  }
  .row--gap-large {
    gap: 60px;
  }
}
@media screen and (min-width: 768px) {
  .row max-width--reverse-mobile {
    flex-direction: column-reverse;
  }
}

.col {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}
.col--center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}
.col--right {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.col-1 {
  padding-left: 15px;
  padding-right: 15px;
  width: 8.3333333333%;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.col-2 {
  padding-left: 15px;
  padding-right: 15px;
  width: 16.6666666667%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.col-3 {
  padding-left: 15px;
  padding-right: 15px;
  width: 25%;
}

.offset-3 {
  margin-left: 25%;
}

.col-4 {
  padding-left: 15px;
  padding-right: 15px;
  width: 33.3333333333%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.col-5 {
  padding-left: 15px;
  padding-right: 15px;
  width: 41.6666666667%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.col-6 {
  padding-left: 15px;
  padding-right: 15px;
  width: 50%;
}

.offset-6 {
  margin-left: 50%;
}

.col-7 {
  padding-left: 15px;
  padding-right: 15px;
  width: 58.3333333333%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.col-8 {
  padding-left: 15px;
  padding-right: 15px;
  width: 66.6666666667%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.col-9 {
  padding-left: 15px;
  padding-right: 15px;
  width: 75%;
}

.offset-9 {
  margin-left: 75%;
}

.col-10 {
  padding-left: 15px;
  padding-right: 15px;
  width: 83.3333333333%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.col-11 {
  padding-left: 15px;
  padding-right: 15px;
  width: 91.6666666667%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

.col-12 {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.offset-12 {
  margin-left: 100%;
}

@media screen and (min-width: 768px) {
  .col-md-1 {
    padding-left: 15px;
    padding-right: 15px;
    width: 8.3333333333%;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .col-md-2 {
    padding-left: 15px;
    padding-right: 15px;
    width: 16.6666666667%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .col-md-3 {
    padding-left: 15px;
    padding-right: 15px;
    width: 25%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .col-md-4 {
    padding-left: 15px;
    padding-right: 15px;
    width: 33.3333333333%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .col-md-5 {
    padding-left: 15px;
    padding-right: 15px;
    width: 41.6666666667%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .col-md-6 {
    padding-left: 15px;
    padding-right: 15px;
    width: 50%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .col-md-7 {
    padding-left: 15px;
    padding-right: 15px;
    width: 58.3333333333%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .col-md-8 {
    padding-left: 15px;
    padding-right: 15px;
    width: 66.6666666667%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .col-md-9 {
    padding-left: 15px;
    padding-right: 15px;
    width: 75%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .col-md-10 {
    padding-left: 15px;
    padding-right: 15px;
    width: 83.3333333333%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .col-md-11 {
    padding-left: 15px;
    padding-right: 15px;
    width: 91.6666666667%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
  .col-md-12 {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }
  .offset-md-12 {
    margin-left: 100%;
  }
}
@media screen and (min-width: 1220px) {
  .col-lg-1 {
    padding-left: 15px;
    padding-right: 15px;
    width: 8.3333333333%;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .col-lg-2 {
    padding-left: 15px;
    padding-right: 15px;
    width: 16.6666666667%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .col-lg-3 {
    padding-left: 15px;
    padding-right: 15px;
    width: 25%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .col-lg-4 {
    padding-left: 15px;
    padding-right: 15px;
    width: 33.3333333333%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .col-lg-5 {
    padding-left: 15px;
    padding-right: 15px;
    width: 41.6666666667%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .col-lg-6 {
    padding-left: 15px;
    padding-right: 15px;
    width: 50%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .col-lg-7 {
    padding-left: 15px;
    padding-right: 15px;
    width: 58.3333333333%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .col-lg-8 {
    padding-left: 15px;
    padding-right: 15px;
    width: 66.6666666667%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .col-lg-9 {
    padding-left: 15px;
    padding-right: 15px;
    width: 75%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .col-lg-10 {
    padding-left: 15px;
    padding-right: 15px;
    width: 83.3333333333%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .col-lg-11 {
    padding-left: 15px;
    padding-right: 15px;
    width: 91.6666666667%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
  .col-lg-12 {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }
  .offset-lg-12 {
    margin-left: 100%;
  }
}
.dateline, .content-intro__dateline {
  display: inline-block;
  margin: 0;
  padding-left: 18px;
  font-size: 12px;
  line-height: 1;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  border-left: 2px solid #92bce8;
}
@media screen and (min-width: 768px) {
  .dateline, .content-intro__dateline {
    font-size: 14px;
  }
}

body {
  background: #eff2f5;
}

figcaption {
  padding: 10px 0;
  font-size: 14px;
  line-height: 18px;
  color: #92bce8;
}

a[data-lightbox-target=element] {
  display: block;
  position: relative;
}
a[data-lightbox-target=element]::before {
  content: "";
  display: block;
  position: absolute;
  inset: 0;
  background: url("../../images/zoom.svg") 50% 50%/38px 38px no-repeat;
  z-index: 10;
  pointer-events: none;
}

.section-header__inside,
#main .mod_article > .inside,
.section-footer__inside {
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
}
@media screen and (min-width: 768px) {
  .section-header__inside,
  #main .mod_article > .inside,
  .section-footer__inside {
    max-width: 1200px;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.mod_article.pattern {
  background: #dce7f2 url("../../images/pattern.png") 0 0/200px 200px repeat;
}
.mod_article.pattern:before {
  display: table;
  clear: both;
  content: "";
}
.mod_article.pattern:after {
  display: table;
  clear: both;
  content: "";
}

.mod_article.pattern-accent {
  overflow: hidden;
  background: #fddd65 url("../../images/pattern.png") 0 0/200px 200px repeat;
}

.mod_article.services {
  overflow: hidden;
  margin-top: -130px;
  padding-top: 60px;
  background: #fddd65 url("../../images/pattern.png") 0 0/200px 200px repeat;
}
@media screen and (min-width: 768px) {
  .mod_article.services {
    margin-top: -230px;
    padding-top: 160px;
  }
}

.mod_article.overflow-hidden {
  overflow: hidden;
}

.mod_article {
  font-size: 18px;
  line-height: 1.55;
  letter-spacing: 0.05em;
}
.mod_article h2 {
  font-size: 36px;
  line-height: 1.2;
  letter-spacing: 0;
}
@media screen and (min-width: 768px) {
  .mod_article h2 {
    font-size: 48px;
    letter-spacing: 0.05em;
  }
}
.mod_article h3 {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 400;
}
.mod_article .dateline + h2, .mod_article .content-intro__dateline + h2 {
  margin: 15px 0 30px;
}
@media screen and (min-width: 768px) {
  .mod_article .dateline + h2, .mod_article .content-intro__dateline + h2 {
    margin: 35px 0 50px;
  }
}
.mod_article > .inside > .ce_rsce_cta,
.mod_article > .inside > .ce_rsce_services-list,
.mod_article > .inside > .ce_rsce_services-more,
.mod_article > .inside > .ce_rsce_dateline:first-child,
.mod_article > .inside > .ce_rsce_grid-row--start:first-child,
.mod_article > .inside > .content-text:first-child {
  margin-top: 70px;
}
@media screen and (min-width: 768px) {
  .mod_article > .inside > .ce_rsce_cta,
  .mod_article > .inside > .ce_rsce_services-list,
  .mod_article > .inside > .ce_rsce_services-more,
  .mod_article > .inside > .ce_rsce_dateline:first-child,
  .mod_article > .inside > .ce_rsce_grid-row--start:first-child,
  .mod_article > .inside > .content-text:first-child {
    margin-top: 100px;
  }
}
.mod_article > .inside > .ce_rsce_cta,
.mod_article > .inside > .ce_rsce_services-list,
.mod_article > .inside > .ce_rsce_services-more,
.mod_article > .inside > .ce_rsce_team,
.mod_article > .inside > .ce_rsce_grid-row--start:last-child,
.mod_article > .inside > .content-text:last-child {
  margin-bottom: 70px;
}
@media screen and (min-width: 768px) {
  .mod_article > .inside > .ce_rsce_cta,
  .mod_article > .inside > .ce_rsce_services-list,
  .mod_article > .inside > .ce_rsce_services-more,
  .mod_article > .inside > .ce_rsce_team,
  .mod_article > .inside > .ce_rsce_grid-row--start:last-child,
  .mod_article > .inside > .content-text:last-child {
    margin-bottom: 100px;
  }
}

.section-header__logo {
  display: block;
  width: 170px;
  height: 75px;
  background: url("../../images/logo.svg") 0 50% no-repeat;
}
.section-header__claim {
  display: none;
}
.section-header__toggle {
  position: absolute;
  right: 20px;
  top: 30px;
  z-index: 100;
  height: 3em;
  width: 3em;
  font-size: 12px;
  background: none;
  border: none;
  cursor: pointer;
  transition: 0.2s all;
  -webkit-tap-highlight-color: transparent;
}
.section-header__toggle:after {
  content: "";
  display: block;
  position: absolute;
  height: 150%;
  width: 150%;
  top: -25%;
  left: -25%;
}
.section-header__toggle > span {
  top: 50%;
  margin-top: -0.125em;
}
.section-header__toggle > span, .section-header__toggle > span:after, .section-header__toggle > span:before {
  pointer-events: none;
  display: block;
  content: "";
  width: 100%;
  border-radius: 0.25em;
  background-color: #92bce8;
  height: 0.25em;
  position: absolute;
  transform: rotate(0);
  transition: all 0.2s;
}
.section-header__toggle > span:after {
  left: 0;
  top: -1em;
}
.section-header__toggle > span:before {
  left: 1em;
  top: 1em;
}
.section-header__toggle > span:after, .section-header__toggle > span:before {
  width: 2em;
}
body.has-overlay .section-header__toggle > span {
  transform: translateX(-2em);
  background-color: transparent;
}
body.has-overlay .section-header__toggle > span:before, body.has-overlay .section-header__toggle > span:after {
  left: 0.5em;
  top: 0;
}
body.has-overlay .section-header__toggle > span:before {
  transform: translateX(2em) rotate(135deg);
}
body.has-overlay .section-header__toggle > span:after {
  transform: translateX(2em) rotate(-135deg);
}
.section-header__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  overflow-y: auto;
  background: #fddd65 url("../../images/pattern.png") 0 0/200px 200px repeat;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}
body.has-overlay .section-header__overlay {
  opacity: 1;
  pointer-events: all;
}
.section-header__language {
  margin-top: 35px;
  text-align: center;
  font-size: 18px;
}
.section-header__language ul {
  display: flex;
  justify-content: center;
}
.section-header__language li::before {
  padding: 0 25px;
  content: ".";
}
.section-header__language li:first-child::before {
  content: none;
}
.section-header__language a {
  color: #73624d;
  transition: color 0.1s ease-in-out;
}
.section-header__language a:hover {
  color: #92bce8 !important;
}
.section-header__language a.active {
  font-weight: 800;
}
.section-header__navigation {
  margin-top: 35px;
  text-align: center;
}
.section-header__navigation a {
  display: block;
  padding: 5px 0;
  color: #73624d;
  transition: color 0.1s ease-in-out;
}
.section-header__navigation a.active, .section-header__navigation a:hover {
  color: #92bce8 !important;
}
.section-header__navigation .level_1 > li > a {
  font-family: Ashby, sans-serif;
  font-weight: 800;
  font-size: 30px;
  text-transform: uppercase;
}
.section-header__navigation .level_2 {
  margin-bottom: 30px;
}
.section-header__navigation .level_2 > li > a {
  font-size: 24px;
}

body.has-overlay {
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .section-header__logo {
    height: 100px;
  }
  .section-header__claim {
    display: block;
    position: absolute;
    top: 40px;
    right: 100px;
    color: #92bce8;
    font-weight: 600;
    text-transform: uppercase;
  }
  .section-header__overlay {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .section-header__language {
    margin: 40px 0;
  }
  .section-header__navigation {
    margin: 0;
  }
  .section-header__navigation .level_1 > li > a {
    padding: 15px 0;
  }
  .section-header__navigation .level_2 {
    font-weight: 300;
  }
}
.section-footer {
  background: #72614c;
  color: #a99174;
  text-align: center;
}
.section-footer__inside {
  padding: 50px 0;
}
.section-footer a {
  text-decoration: underline;
  transition: color 0.1s ease;
}
.section-footer a:hover {
  color: #fddd65;
  text-decoration: underline;
}
.section-footer__navigation {
  font-size: 18px;
  letter-spacing: 0.05em;
}
.section-footer__navigation ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 300px;
  margin: 0 auto;
}
.section-footer__navigation li {
  padding-bottom: 10px;
}
.section-footer__navigation li:nth-child(even)::before {
  padding: 0 20px;
  content: ".";
}
.section-footer__navigation a {
  color: #ddbe98;
  text-decoration: none;
}
.section-footer .social-media {
  margin: 30px;
}
.section-footer .social-media a {
  margin-top: 0;
  margin-bottom: 0;
}
.section-footer .content-hyperlink {
  margin: 35px 20px;
  text-align: center;
}
.section-footer .content-hyperlink img {
  max-width: 400px;
}
.section-footer .content-text, .section-footer__legal {
  font-size: 12px;
  line-height: 2;
  color: #a99174;
}
.section-footer .content-text p, .section-footer__legal p {
  margin: 0;
}
.section-footer .content-text a, .section-footer__legal a {
  color: inherit;
  transition: color 0.1s ease;
}
.section-footer .content-text a:hover, .section-footer__legal a:hover {
  color: #fddd65;
}
.section-footer__legal {
  margin-top: 30px;
  font-size: 10px;
}
.section-footer__legal ul {
  display: flex;
  justify-content: center;
}
.section-footer__legal li::before {
  padding: 0 10px;
  content: "|";
}
.section-footer__legal li:first-child::before {
  content: none;
}

@media screen and (min-width: 768px) {
  .section-footer__navigation ul {
    width: auto;
  }
  .section-footer__navigation li {
    padding-bottom: 10px;
  }
  .section-footer__navigation li::before {
    padding: 0 20px;
    content: ".";
  }
  .section-footer__navigation li:first-child::before {
    content: none;
  }
  .section-footer .rte {
    display: flex;
    justify-content: center;
  }
  .section-footer .rte p::before {
    content: "|";
    padding: 0 15px;
  }
  .section-footer .rte p:first-child::before {
    content: none;
  }
}
.pagination ul {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 70px 0;
  padding: 0;
  list-style: none;
}
.pagination__control {
  flex-shrink: 0;
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
  margin: 0 15px;
  background: none;
  border: 1px solid #92bce8;
  border-radius: 50%;
  transition: opacity 0.1s ease;
}
.pagination__control::before {
  content: "";
  position: absolute;
  top: 17px;
  width: 15px;
  height: 15px;
  border-left: 2px solid #92bce8;
  border-top: 2px solid #92bce8;
  border-radius: 2px;
  pointer-events: none;
}
.pagination__control--prev::before {
  left: 19px;
  transform: rotateZ(-45deg);
}
.pagination__control--next::before {
  right: 19px;
  transform: rotateZ(135deg);
}
.pagination__control--disabled {
  opacity: 0.5;
}
.pagination__control a, .pagination__control span {
  display: block;
  width: 100%;
  height: 100%;
  padding: 15px 35px;
  text-indent: -999em;
}
.pagination__link {
  display: flex;
  align-items: baseline;
}
.pagination__link a, .pagination__link strong {
  display: block;
  padding: 15px;
  font-weight: 300;
}
.pagination__link a {
  color: #92bce8;
}
.pagination__link a:hover {
  text-decoration: underline;
}
.pagination__link::after {
  content: ".";
  color: #92bce8;
}
.pagination__link:nth-child(6n)::after {
  content: none;
}

@media screen and (min-width: 768px) {
  .pagination__link a, .pagination__link strong {
    padding-left: 35px;
    padding-right: 35px;
  }
}
.share {
  display: flex;
  justify-content: space-between;
  max-width: 330px;
  margin: 60px auto 100px;
}
.share__icon {
  display: block;
  width: 40px;
  height: 40px;
  color: #92bce8;
  transition: color 0.1s ease;
}
.share__icon:hover {
  color: #fddd65;
}
.share + .content-text {
  margin-top: -2em;
}

.tns-outer {
  display: flex;
  flex-direction: column-reverse;
}

.tns-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}
.tns-nav button {
  margin: 0 5px;
  width: 5px;
  height: 5px;
  background: #92bce8;
  border: none;
  border-radius: 50%;
}
.tns-nav button.tns-nav-active {
  background: #8a8e8d;
}
@media screen and (min-width: 768px) {
  .tns-nav button {
    margin: 0 10px;
    width: 10px;
    height: 10px;
  }
}

.tns-controls,
.slider__controls {
  display: flex;
  gap: 12px;
}
.tns-controls .slider__control,
.tns-controls button,
.slider__controls .slider__control,
.slider__controls button {
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
  background: none;
  border: 1px solid #92bce8;
  border-radius: 50%;
  text-indent: -999em;
  cursor: pointer;
  transition: opacity 0.1s ease;
}
.tns-controls .slider__control::before,
.tns-controls button::before,
.slider__controls .slider__control::before,
.slider__controls button::before {
  content: "";
  position: absolute;
  top: 17px;
  width: 15px;
  height: 15px;
  border-left: 2px solid #92bce8;
  border-top: 2px solid #92bce8;
  border-radius: 2px;
  pointer-events: none;
}
.tns-controls .slider__control--prev::before, .tns-controls .slider__control[data-controls=prev]::before,
.tns-controls button--prev::before,
.tns-controls button[data-controls=prev]::before,
.slider__controls .slider__control--prev::before,
.slider__controls .slider__control[data-controls=prev]::before,
.slider__controls button--prev::before,
.slider__controls button[data-controls=prev]::before {
  left: 19px;
  transform: rotateZ(-45deg);
}
.tns-controls .slider__control--next::before, .tns-controls .slider__control[data-controls=next]::before,
.tns-controls button--next::before,
.tns-controls button[data-controls=next]::before,
.slider__controls .slider__control--next::before,
.slider__controls .slider__control[data-controls=next]::before,
.slider__controls button--next::before,
.slider__controls button[data-controls=next]::before {
  right: 19px;
  transform: rotateZ(135deg);
}
.tns-controls .slider__control:hover,
.tns-controls button:hover,
.slider__controls .slider__control:hover,
.slider__controls button:hover {
  background: #92bce8;
}
.tns-controls .slider__control:hover::before,
.tns-controls button:hover::before,
.slider__controls .slider__control:hover::before,
.slider__controls button:hover::before {
  border-color: #fff;
}
.tns-controls .slider__control:disabled,
.tns-controls button:disabled,
.slider__controls .slider__control:disabled,
.slider__controls button:disabled {
  opacity: 0.5;
  cursor: auto;
}
.tns-controls .slider__control:disabled:hover,
.tns-controls button:disabled:hover,
.slider__controls .slider__control:disabled:hover,
.slider__controls button:disabled:hover {
  background: inherit;
}
.tns-controls .slider__control:disabled::before,
.tns-controls button:disabled::before,
.slider__controls .slider__control:disabled::before,
.slider__controls button:disabled::before {
  border-color: inherit;
}

.ce_rsce_cta {
  overflow: hidden;
  flex-direction: column-reverse;
}
.ce_rsce_cta figure {
  display: block;
}
.ce_rsce_cta figure img {
  width: auto;
  max-width: none;
  height: 380px;
}
.ce_rsce_cta__headline {
  margin: 0 0 30px;
  font-family: Ashby, sans-serif;
  font-weight: 800;
  font-size: 30px;
  line-height: 1;
  text-transform: uppercase;
}
.ce_rsce_cta__headline span {
  display: block;
  color: #92bce8;
}
.ce_rsce_cta__headline span:first-child {
  color: #72614c;
}
.ce_rsce_cta__text {
  margin: 0 0 40px;
}
.ce_rsce_cta__text br {
  display: none;
}
@media screen and (min-width: 768px) {
  .ce_rsce_cta__text br {
    display: initial;
  }
}
.ce_rsce_cta__link {
  margin: 0;
  position: relative;
  display: inline-block;
  color: #92bce8;
  font-size: 14px;
  line-height: 1.3;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  background-image: linear-gradient(transparent 4px, #fddd65 4px, #fddd65 calc(100% - 10px));
  background-repeat: no-repeat;
  background-size: calc(100% - 30px) 333%;
  transition: all 0.3s;
  background-position: 100% calc(1em - 10px);
  padding-right: 30px;
  box-decoration-break: clone;
}
.ce_rsce_cta__link::after {
  content: "";
  position: absolute;
  top: 0;
  right: -24px;
  width: 14px;
  height: 100%;
  background: url("../images/arrow.svg") 50% 50%/contain no-repeat;
}
.ce_rsce_cta__link.active, .ce_rsce_cta__link:hover {
  background-size: 100% 100%;
  background-position: calc(100% - 30px) 0;
}
.ce_rsce_cta--layout-home {
  overflow: visible;
}
.ce_rsce_cta--layout-home figure {
  margin: 50px -15px 0 20px;
}
.ce_rsce_cta--layout-home .ce_rsce_cta__headline {
  font-size: 65px;
}

@media screen and (min-width: 768px) {
  .ce_rsce_cta {
    flex-direction: row;
  }
  .ce_rsce_cta__headline {
    width: 81.8181818182%;
    font-size: 60px;
  }
  .ce_rsce_cta--layout-home {
    margin-bottom: 180px !important;
  }
  .ce_rsce_cta--layout-home figure {
    position: absolute;
    top: -40px;
    left: 68.3333333333%;
    z-index: -1;
    margin: 0;
  }
  .ce_rsce_cta--layout-home figure img {
    height: 530px;
  }
  .ce_rsce_cta--layout-home .ce_rsce_cta__headline {
    font-size: 100px;
  }
  .ce_rsce_cta--layout-home .ce_rsce_cta__text {
    width: 45.4545454545%;
    font-size: 24px;
    line-height: 1.5;
  }
}
.content-intro {
  padding: 75px 0;
  text-align: center;
}
.content-intro + .share {
  margin-top: -30px;
}
.content-intro__headline {
  margin: 40px 0 20px;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.05em;
}
.content-intro__headline span {
  display: block;
  color: #92bce8;
}
.content-intro__headline span:first-child {
  color: #72614c;
}
.content-intro__text {
  font-size: 15px;
}
.content-intro__text a {
  text-decoration: underline;
}
.content-intro__text br {
  display: none;
}
@media screen and (min-width: 768px) {
  .content-intro__text br {
    display: initial;
  }
}
.content-intro__image {
  margin: 100px -20px 0;
}
.content-intro__image img {
  width: 100%;
}

.content-intro--main .content-intro__headline {
  font-family: Ashby, sans-serif;
  font-weight: 800;
  text-transform: uppercase;
}
.content-intro--main .content-intro__headline span {
  display: block;
  color: #92bce8;
}
.content-intro--main .content-intro__headline span:first-child {
  color: #72614c;
}

@media screen and (min-width: 768px) {
  .content-intro__headline {
    padding-left: 15px;
    padding-right: 15px;
    width: 83.3333333333%;
    margin-left: 8.3333333333%;
    margin-bottom: 50px;
    font-size: 60px;
  }
  .content-intro__text {
    padding-left: 15px;
    padding-right: 15px;
    width: 66.6666666667%;
    margin-left: 16.6666666667%;
    font-size: 20px;
    line-height: 30px;
  }
  .content-intro--main {
    padding-bottom: 200px;
  }
  .content-intro--main .content-intro__headline {
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 80px;
  }
  .content-intro__image {
    margin: 200px 0 0;
  }
}
.content-text figure {
  margin: 40px 0;
}
body.layout--text .content-text h2 {
  margin-top: 2.2em;
  font-size: 30px;
  font-weight: 700;
}
body.layout--text .content-text h3 {
  margin-top: 2.2em;
  font-weight: 600;
  letter-spacing: 0;
}
.content-text a {
  color: #92bce8;
  font-weight: 600;
}
.content-text a:hover {
  text-decoration: underline;
}
.content-text p {
  margin: 2em 0;
}
.content-text li {
  margin: 16px 0;
}
.content-text.claim {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 120px !important;
  padding-left: 60px;
  padding-right: 60px;
  font: italic 50px/1 Tinos, serif;
  text-align: center;
  color: #dddddd;
}
.content-text.claim:before {
  content: "";
  position: absolute;
  top: 50%;
  left: -1000em;
  right: -1000em;
  z-index: -1;
  height: 5px;
  background: #dddddd;
}
.content-text.claim p {
  display: inline-block;
  max-width: 760px;
  margin: 0;
  padding: 0 30px;
  background: #eff2f5;
}
@media screen and (min-width: 768px) {
  .content-text.claim {
    font-size: 80px;
  }
}

@media screen and (min-width: 768px) {
  .content-text.claim {
    margin-top: 200px !important;
  }
}
@media screen and (min-width: 1220px) {
  .content-text.media--right {
    position: relative;
  }
  .content-text.media--right figure {
    position: absolute;
    top: 50%;
    right: 0;
    width: 680px;
    margin: 0;
    transform: translate(8.3333333333%, -50%);
  }
  .content-text.media--right h1, .content-text.media--right h2, .content-text.media--right h3, .content-text.media--right p {
    max-width: 41.6666666667%;
  }
}
.content-team {
  margin: 70px 0 20px;
}
.content-team__header {
  margin-bottom: 60px;
  text-align: center;
}
.content-team__filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 75px 0 45px;
  padding: 0;
  list-style: none;
}
.content-team__filters-break {
  display: block;
  width: 500%;
}
.content-team__filters li {
  position: relative;
  padding: 5px 0 5px 40px;
}
.content-team__filters li button {
  line-height: 28px;
  color: #92bce8;
  background: none;
  border: none;
  cursor: pointer;
}
.content-team__filters li:first-child {
  padding-right: 20px;
}
.content-team__filters li:nth-child(3n+2):before {
  position: absolute;
  left: 10px;
  content: ".";
  color: #92bce8;
}
.content-team__filters li:nth-child(1n+2) button {
  background-image: linear-gradient(transparent 4px, #fddd65 4px, #fddd65 calc(100% - 10px));
  background-repeat: no-repeat;
  background-size: calc(100% - 20px) 333%;
  transition: all 0.3s;
  background-position: 100% calc(1em - 10px);
  padding-right: 20px;
  box-decoration-break: clone;
}
.content-team__filters li:nth-child(1n+2) button.active, .content-team__filters li:nth-child(1n+2) button:hover {
  background-size: 100% 100%;
  background-position: calc(100% - 20px) 0;
}
.content-team__item {
  margin-bottom: 55px;
  font-size: 14px;
}
.content-team__image {
  position: relative;
}
.content-team__image img {
  width: 100%;
}
.content-team__image p {
  display: flex;
  align-items: center;
  overflow-y: auto;
  position: absolute;
  inset: 0;
  z-index: 10;
  margin: 0;
  padding: 20px 10px;
  background: rgba(38, 32, 24, 0.5647058824);
  line-height: 24px;
  color: #fff;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  cursor: help;
}
.content-team__image.active p {
  opacity: 1;
}
.content-team__headline {
  margin: 30px 0 0;
  font-size: 18px;
  font-weight: 700;
}
.content-team__title {
  margin: 10px 0;
  text-transform: uppercase;
}
.content-team a {
  color: #92bce8;
  font-weight: 600;
}
.content-team a:hover {
  text-decoration: underline;
}

@media screen and (min-width: 768px) {
  .content-team {
    margin: 100px 0 50px;
  }
  .content-team__header {
    width: 66.6666666667%;
    margin-left: auto;
    margin-right: auto;
  }
  .content-team__filters-break {
    display: none !important;
  }
  .content-team__filters li {
    padding-left: 60px;
  }
  .content-team__filters li:nth-child(1n+2):before {
    position: absolute;
    left: 20px;
    content: ".";
    color: #92bce8;
  }
  .content-team__image p {
    padding: 20px 30px;
  }
}
.social-media ul {
  display: flex;
  justify-content: center;
}
.social-media a {
  display: block;
  width: 20px;
  height: 20px;
  margin: 40px 15px;
  text-indent: -999em;
  background: url("../../images/social.svg") 0 0/60px 80px no-repeat;
}
.social-media a.twitter {
  background-position: 0 0;
}
.social-media a.twitter:hover {
  background-position: 0 -20px;
}
.section-footer .social-media a.twitter {
  background-position: 0 -40px;
}
.section-footer .social-media a.twitter:hover {
  background-position: 0 -60px;
}
.social-media a.linkedin {
  background-position: -20px 0;
}
.social-media a.linkedin:hover {
  background-position: -20px -20px;
}
.section-footer .social-media a.linkedin {
  background-position: -20px -40px;
}
.section-footer .social-media a.linkedin:hover {
  background-position: -20px -60px;
}
.social-media a.facebook {
  background-position: -40px 0;
}
.social-media a.facebook:hover {
  background-position: -40px -20px;
}
.section-footer .social-media a.facebook {
  background-position: -40px -40px;
}
.section-footer .social-media a.facebook:hover {
  background-position: -40px -60px;
}

.ce_rsce_services-list,
.ce_rsce_services-more,
.ce_rsce_services-details {
  position: relative;
  margin: 0 -20px;
}
@media screen and (min-width: 768px) {
  .ce_rsce_services-list,
  .ce_rsce_services-more,
  .ce_rsce_services-details {
    margin: 0 -15px;
  }
  .ce_rsce_services-list:before,
  .ce_rsce_services-more:before,
  .ce_rsce_services-details:before {
    left: 15px;
    right: 15px;
  }
}

.ce_rsce_services-list,
.ce_rsce_services-more {
  position: relative;
  margin-left: 0;
  margin-right: 0;
  padding: 50px 20px 50px 40px;
  background: #fddd65 url("../../images/pattern.png") 0 0/200px 200px repeat;
}
.ce_rsce_services-list li,
.ce_rsce_services-more li {
  padding: 15px 20px 15px 40px;
  font-size: 24px;
}
.ce_rsce_services-list a,
.ce_rsce_services-more a {
  background-image: linear-gradient(transparent 4px, #92bce8 4px, #92bce8 calc(100% - 10px));
  background-repeat: no-repeat;
  background-size: calc(100% - 30px) 333%;
  transition: all 0.3s;
  background-position: 100% calc(1em - 10px);
  padding-right: 30px;
  box-decoration-break: clone;
}
.ce_rsce_services-list a.active, .ce_rsce_services-list a:hover,
.ce_rsce_services-more a.active,
.ce_rsce_services-more a:hover {
  background-size: 100% 100%;
  background-position: calc(100% - 30px) 0;
}

@media screen and (min-width: 768px) {
  .ce_rsce_services-list {
    padding: 50px 0;
  }
  .ce_rsce_services-list header {
    padding-left: 15px;
    padding-right: 15px;
    width: 50%;
    margin-left: 8.3333333333%;
  }
  .ce_rsce_services-list ul {
    display: flex;
    flex-wrap: wrap;
  }
  .ce_rsce_services-list li {
    padding-left: 15px;
    padding-right: 15px;
    width: 16.6666666667%;
    margin-left: 8.3333333333%;
  }
  .ce_rsce_services-list li:first-child, .ce_rsce_services-list li:nth-child(3n+1) {
    margin-left: 16.6666666667%;
  }
}
@media screen and (min-width: 768px) {
  .ce_rsce_services-more {
    padding: 60px 15px 80px;
  }
  .ce_rsce_services-more header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .ce_rsce_services-more ul {
    display: flex;
    justify-content: space-between;
  }
  .ce_rsce_services-more li {
    padding-left: 10px;
    padding-right: 40px;
  }
}
.ce_rsce_services-details {
  padding-bottom: 40px;
}
.ce_rsce_services-details__item {
  margin-top: 40px;
  margin-left: 40px;
  font-size: 18px;
  line-height: 28px;
}
.ce_rsce_services-details__headline a {
  background-image: linear-gradient(transparent 4px, #92bce8 4px, #92bce8 calc(100% - 10px));
  background-repeat: no-repeat;
  background-size: calc(100% - 30px) 333%;
  transition: all 0.3s;
  background-position: 100% calc(1em - 10px);
  padding-right: 30px;
  box-decoration-break: clone;
}
.ce_rsce_services-details__headline a.active, .ce_rsce_services-details__headline a:hover {
  background-size: 100% 100%;
  background-position: calc(100% - 30px) 0;
}
.ce_rsce_services-details__link {
  display: inline-block;
  position: relative;
  display: inline-block;
  color: #92bce8;
  font-size: 14px;
  line-height: 1.3;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.ce_rsce_services-details__link::after {
  content: "";
  position: absolute;
  top: 0;
  right: -44px;
  width: 14px;
  height: 100%;
  background: url("../images/arrow.svg") 50% 50%/contain no-repeat;
}

@media screen and (min-width: 768px) {
  .ce_rsce_services-details__item {
    margin-bottom: 40px;
  }
  .ce_rsce_services-details__headline {
    margin-bottom: 34px;
  }
  .ce_rsce_services-details__link {
    margin-top: 25px;
  }
}
.content-clients {
  margin: 70px 0;
}
.content-clients__controls {
  display: flex;
  align-items: flex-end;
  margin-bottom: 35px;
}
.content-clients__headline {
  margin-bottom: 0;
}
.content-clients figure {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 120px;
  margin: 0 auto;
  padding: 20px;
}
.content-clients figure img {
  max-height: 100%;
}

@media screen and (min-width: 768px) {
  .content-clients {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }
  .content-clients > * {
    padding-left: 15px;
    padding-right: 15px;
  }
  .content-clients__header {
    padding-left: 15px;
    padding-right: 15px;
    width: 33.3333333333%;
  }
  .content-clients__controls {
    flex-direction: column;
  }
  .content-clients__headline {
    margin-right: 40px;
  }
  .content-clients__main {
    padding-left: 15px;
    padding-right: 15px;
    width: 66.6666666667%;
  }
  .content-clients__main figure {
    width: 100%;
    margin: 0;
    padding: 20px 0;
  }
}
.content-topics__navigation nav {
  padding-left: 40px;
}
.content-topics__navigation a, .content-topics__navigation button {
  display: block;
  padding: 10px 0;
  color: #92bce8;
  background: none;
  border: none;
  cursor: pointer;
}
.content-topics__navigation--desktop {
  display: none;
}
.content-topics__item {
  margin-top: 75px;
}
.content-topics__title {
  font-weight: 600;
}
.content-topics__text {
  font-size: 16px;
  letter-spacing: 0;
}
.content-topics__link {
  position: relative;
  display: inline-block;
  color: #92bce8;
  font-size: 14px;
  line-height: 1.3;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  background-image: linear-gradient(transparent 4px, #fddd65 4px, #fddd65 calc(100% - 10px));
  background-repeat: no-repeat;
  background-size: calc(100% - 30px) 333%;
  transition: all 0.3s;
  background-position: 100% calc(1em - 10px);
  padding-right: 30px;
  box-decoration-break: clone;
}
.content-topics__link::after {
  content: "";
  position: absolute;
  top: 0;
  right: -44px;
  width: 14px;
  height: 100%;
  background: url("../images/arrow.svg") 50% 50%/contain no-repeat;
}
.content-topics__link.active, .content-topics__link:hover {
  background-size: 100% 100%;
  background-position: calc(100% - 30px) 0;
}

@media screen and (min-width: 768px) {
  .content-topics {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }
  .content-topics > * {
    padding-left: 15px;
    padding-right: 15px;
  }
  .content-topics__header {
    padding-left: 15px;
    padding-right: 15px;
    width: 41.6666666667%;
  }
  .content-topics__main {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }
  .content-topics__main > * {
    padding-left: 15px;
    padding-right: 15px;
  }
  .content-topics__navigation {
    padding-left: 15px;
    padding-right: 15px;
    width: 41.6666666667%;
  }
  .content-topics__navigation--mobile {
    display: none;
  }
  .content-topics__navigation--desktop {
    display: block;
  }
  .content-topics__navigation button {
    position: relative;
    padding-left: 0;
    transition: padding-left 0.2s ease;
  }
  .content-topics__navigation button:before {
    content: "";
    position: absolute;
    left: 0;
    top: 19px;
    width: 0;
    height: 3px;
    background: #92bce8;
    transition: width 0.2s ease;
  }
  .content-topics__navigation button:hover, .content-topics__navigation button.tns-nav-active {
    padding-left: 40px;
  }
  .content-topics__navigation button:hover:before, .content-topics__navigation button.tns-nav-active:before {
    width: 28px;
  }
  .content-topics__items {
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    width: 41.6666666667%;
    margin-left: 8.3333333333%;
  }
  .content-topics__items::before {
    content: "";
    position: absolute;
    inset: -100px 0 -100px -150px;
    background: url("../../images/cloud.svg") center center/contain no-repeat;
  }
  .content-topics__item {
    margin-top: 0;
  }
  .content-topics__title {
    margin-top: 0;
    font-weight: 300;
  }
  .content-topics__link {
    margin-top: 40px;
  }
}
.content-contact {
  margin-top: 60px;
  margin-bottom: 120px;
}
.content-contact__background {
  margin: 0 -20px;
  padding: 60px 20px;
  background: #e6e7e9;
}
.content-contact__headline {
  margin: 0 0 40px;
  font-size: 24px;
  line-height: 1.2;
  font-weight: 600;
}
.content-contact a {
  font-weight: 600;
}
.content-contact p.address {
  text-transform: uppercase;
}
.content-contact p.address,
.content-contact p.phone,
.content-contact p.email,
.content-contact p.website {
  font-size: 14px;
  line-height: 24px;
}

@media screen and (min-width: 768px) {
  .content-contact {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }
  .content-contact > * {
    padding-left: 15px;
    padding-right: 15px;
  }
  .content-contact__wrapper {
    padding-left: 15px;
    padding-right: 15px;
    width: 83.3333333333%;
    margin-left: 8.3333333333%;
  }
  .content-contact__background {
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .content-contact__row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }
  .content-contact__row > * {
    padding-left: 15px;
    padding-right: 15px;
  }
  .content-contact__headline {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    margin-left: 10%;
  }
  .content-contact__image {
    padding-left: 15px;
    padding-right: 15px;
    width: 40%;
    margin-left: 10%;
  }
  .content-contact__contact {
    padding-left: 15px;
    padding-right: 15px;
    width: 50%;
  }
}
.content-pitch {
  margin: 90px 0 70px;
}
.content-pitch:before {
  content: "";
  position: absolute;
  top: -1.5em;
  display: block;
  width: 57px;
  height: 6px;
  background: #92bce8;
}
.content-pitch__text {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.05em;
}
.content-pitch__gallery {
  margin-bottom: -165px;
}
.content-pitch__gallery .tns-outer {
  margin-right: -20px;
}
.content-pitch__controls {
  justify-content: flex-end;
  margin: 1.5em 0;
}
.content-pitch__slider img {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .content-pitch {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }
  .content-pitch > * {
    padding-left: 15px;
    padding-right: 15px;
  }
  .content-pitch:before {
    left: 15px;
  }
  .content-pitch__text {
    padding-left: 15px;
    padding-right: 15px;
    width: 50%;
  }
  .content-pitch__gallery {
    padding-left: 15px;
    padding-right: 15px;
    width: 50%;
    align-self: flex-end;
  }
  .content-pitch__gallery .tns-outer {
    margin-right: -15px;
  }
  .content-pitch__controls {
    position: absolute;
    right: calc(50% + 15px);
    bottom: -200px;
  }
}
@media screen and (min-width: 1220px) {
  .content-pitch:before {
    top: 1.5em;
    left: 43px;
  }
  .content-pitch__text {
    padding-left: 15px;
    padding-right: 15px;
    width: 41.6666666667%;
    margin-left: 8.3333333333%;
  }
  .content-pitch__gallery .tns-outer {
    margin-right: 0;
  }
}
.content-accordion {
  margin-bottom: 2em;
}
.content-accordion .handorgel__header__button {
  position: relative;
  padding: 30px 0 10px 25px;
  font-weight: 700;
  background: none;
  border-bottom: 1px solid #2d2d2d;
}
.content-accordion .handorgel__header__button::before {
  content: "";
  position: absolute;
  top: 34px;
  left: 5px;
  display: block;
  width: 7px;
  height: 13px;
  background: url("../../images/accordion.svg") 0 0/contain no-repeat;
  transition: transform 0.2s ease;
}
.content-accordion .handorgel__header--open .handorgel__header__button::before {
  transform: rotateZ(90deg);
}
.content-accordion .handorgel__content {
  border: none;
  background: none;
}
.content-accordion .handorgel__content__inner {
  padding: 0 0 0 25px;
}

.news-list__filters {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  margin: 0 -15px 30px;
  padding: 30px 35px;
}
.news-list__filters:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 15px;
  right: 15px;
  background: #e6e7e9;
}
.news-list__filters button {
  width: 100%;
  text-align: start;
  background: none;
  border: none;
  cursor: pointer;
}
.news-list__filters button.current {
  height: 40px;
  padding: 0 20px;
  overflow: hidden;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: #eff2f5 url("../../images/dropdown.svg") 95% 50%/15px 8px no-repeat;
}
.news-list__filter {
  position: relative;
  font-size: 14px;
}
.news-list__filter > ul {
  position: absolute;
  width: 100%;
  top: 40px;
  background: #fff;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.1s ease-in-out;
}
.news-list__filter > ul.active {
  opacity: 1;
  pointer-events: all;
  z-index: 10;
}
.news-list__filter--clients > ul > li {
  border-bottom: 1px solid #dddddd;
}
.news-list__filter--clients > ul > li > button,
.news-list__filter--clients > ul > li > a {
  display: block;
  padding: 15px 10px;
  border-left: 10px solid transparent;
}
.news-list__filter--clients > ul > li > button:hover,
.news-list__filter--clients > ul > li > a:hover {
  color: #92bce8;
}
.news-list__filter--clients > ul > li > ul {
  display: none;
  background: #fff;
}
.news-list__filter--clients > ul > li > ul > li {
  break-inside: avoid;
  page-break-inside: avoid;
  padding: 0 30px 15px;
}
.news-list__filter--clients > ul > li > ul > li > strong {
  display: block;
  padding: 15px 0;
  color: #72614c;
  font-weight: 300;
}
.news-list__filter--clients > ul > li > ul > li > ul {
  padding: 0;
}
.news-list__filter--clients > ul > li > ul > li > ul > li a {
  display: block;
  padding: 3px 0;
}
.news-list__filter--clients > ul > li > ul > li > ul > li a:hover {
  color: #92bce8;
}
.news-list__filter--clients > ul > li.active > button {
  color: #92bce8;
  border-left-color: #fddd65;
}
.news-list__filter--clients > ul > li.active > ul {
  display: block;
}
.news-list__filter--categories ul {
  padding-bottom: 10px;
}
.news-list__filter--categories a {
  display: block;
  padding: 6px 20px;
}
.news-list__filter--categories a:hover {
  color: #92bce8;
}
.news-list__search input {
  width: 100%;
  height: 40px;
  padding: 0 20px;
  font-size: 14px;
  line-height: 40px;
  font-weight: 600;
  background: #eff2f5 url("../../images/search.svg") 95% 50%/15px 15px no-repeat;
  border: none;
}
.news-list__search input:focus {
  background-color: #fff;
}
.news-list__search input::placeholder {
  font-weight: 300;
  font-style: italic;
}
.news-list__filters--clients-active .news-list__filter--clients button.current {
  background: #fff;
}
.news-list__filters--clients-active .news-list__filter--categories button.current,
.news-list__filters--clients-active .news-list__filter--categories input,
.news-list__filters--clients-active .news-list__search button.current,
.news-list__filters--clients-active .news-list__search input {
  color: #c9cacc;
  background: #e8e9eb;
}
.news-list__filters--categories-active .news-list__filter--categories button.current {
  background: #fff;
}
.news-list__filters--categories-active .news-list__filter--clients button.current,
.news-list__filters--categories-active .news-list__filter--clients input,
.news-list__filters--categories-active .news-list__search button.current,
.news-list__filters--categories-active .news-list__search input {
  color: #c9cacc;
  background: #e8e9eb;
}
.news-list__empty {
  flex-grow: 1;
  margin-bottom: 70px;
  text-align: center;
}
.news-list__item--pos1 {
  position: relative;
}
.news-list__item--pos1 .news-list__item-content {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3) 0, rgba(0, 0, 0, 0.5) 50%, #000 100%);
  font-size: 18px;
  color: #fff;
  pointer-events: none;
}
.news-list__item--pos1 .news-list__item-headline {
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
}
.news-list__item--pos1 .news-list__item-client {
  pointer-events: all;
}

.news-list__item,
.news-client__item {
  margin: 0 0 70px;
  line-height: 1.25;
}
.news-list__item-poster,
.news-client__item-poster {
  background: url("../../images/news.png") center center/cover no-repeat;
}
.news-list__item-poster img,
.news-client__item-poster img {
  width: 100%;
}
.news-list__item-headline,
.news-client__item-headline {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.08em;
  hyphens: auto;
}
.news-list__item-client,
.news-client__item-client {
  display: block;
  margin: 0.25em 0;
  font-weight: 700;
  letter-spacing: 0.05em;
}
.news-list__item-date,
.news-client__item-date {
  display: block;
  margin: 0.25em 0;
  letter-spacing: 0.05em;
}

@media screen and (min-width: 768px) {
  .news-list,
  .news-client {
    margin-bottom: 100px;
  }
  .news-list__items,
  .news-client__items {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }
  .news-list__items > *,
  .news-client__items > * {
    padding-left: 15px;
    padding-right: 15px;
  }
  .news-list__item,
  .news-client__item {
    padding-left: 15px;
    padding-right: 15px;
    width: 33.3333333333%;
  }
  .news-list .pagination ul,
  .news-client .pagination ul {
    margin-top: 20px;
  }
  .news-list__filters {
    flex-direction: row;
    gap: 0;
    position: relative;
    padding-left: 0;
    padding-right: 0;
  }
  .news-list__filter {
    padding-left: 15px;
    padding-right: 15px;
    width: 25%;
  }
  .news-list__filter--clients {
    margin-left: 8.3333333333%;
  }
  .news-list__filter--clients > ul > li > ul {
    position: absolute;
    top: 0;
    left: 100%;
    width: calc(300% - 10px);
    columns: 3;
  }
  .news-list__filter--clients > ul > li > ul > li {
    break-inside: avoid;
    page-break-inside: avoid;
    padding: 0 30px 15px;
  }
  .news-list__filter > ul {
    width: calc(100% - 30px);
  }
  .news-list__search {
    padding-left: 15px;
    padding-right: 15px;
    width: 33.3333333333%;
  }
  .news-list__item--pos1 {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }
  .news-list__item--pos1 .news-list__item-content {
    right: 15px;
    left: 15px;
    padding: 30px;
  }
  .news-list__item--pos1 .news-list__item-headline {
    margin-bottom: 35px;
  }
  .news-list__item--pos2, .news-list__item--pos3 {
    padding-left: 15px;
    padding-right: 15px;
    width: 50%;
  }
  .news-list__item--pos2 .news-list__item-headline, .news-list__item--pos3 .news-list__item-headline {
    font-size: 24px;
  }
}
.news-reader {
  margin-bottom: 70px;
}
.news-reader__headline {
  hyphens: auto;
}
.news-reader__poster {
  margin-left: -20px;
  margin-right: -20px;
  background: url("../../images/news.png") center center/cover no-repeat;
}
.news-reader__poster img {
  width: 100%;
}
.news-reader__poster figcaption {
  display: none;
}
.news-reader__downloads {
  display: flex;
  justify-content: space-around;
  margin-left: -20px;
  margin-right: -20px;
  padding: 20px;
  background: #fddd65 url("../../images/pattern.png") 0 0/200px 200px repeat;
}
.news-reader__download {
  display: block;
  padding: 38px 10px 0;
  color: #92bce8;
  font-size: 12px;
  line-height: 1.4;
  text-align: center;
  letter-spacing: 0.08em;
  font-weight: 700;
}
.news-reader__download--pdf {
  background: url("../../images/pdf.svg") 50% 0/auto 25px no-repeat;
}
.news-reader__download--images {
  background: url("../../images/images.svg") 50% 0/auto 22px no-repeat;
}
.news-reader__main {
  padding: 40px 0;
}
.news-reader__main .content-text {
  margin-bottom: 3em;
}
.news-reader__main h2 {
  font-size: 24px;
  font-weight: 600;
}
.news-reader__main .content-hyperlink {
  margin-bottom: 80px;
}
.news-reader__main .content-hyperlink a {
  color: #92bce8;
  font-weight: 600;
  background-image: linear-gradient(transparent 4px, #fddd65 4px, #fddd65 calc(100% - 10px));
  background-repeat: no-repeat;
  background-size: calc(100% - 30px) 333%;
  transition: all 0.3s;
  background-position: 100% calc(1em - 10px);
  padding-right: 30px;
  box-decoration-break: clone;
}
.news-reader__main .content-hyperlink a.active, .news-reader__main .content-hyperlink a:hover {
  background-size: 100% 100%;
  background-position: calc(100% - 30px) 0;
}
.news-reader__intro {
  font-weight: 700;
}
.news-reader__download-headline {
  margin-bottom: 30px;
}
.news-reader__files {
  margin-bottom: 75px;
  font-size: 14px;
  letter-spacing: 0.08em;
}
.news-reader__files ul {
  list-style: none;
  margin: 0;
  padding: 10px 30px;
}
.news-reader__files a {
  display: block;
  padding-left: 30px;
  background: url("../../images/pdf.svg") 0 0/20px no-repeat;
  line-height: 30px;
  font-weight: 600;
  color: #92bce8;
}
.news-reader__gallery .tns-ovh {
  position: relative;
  overflow: visible !important;
}
.news-reader__gallery .tns-ovh::before, .news-reader__gallery .tns-ovh::after {
  content: "";
  position: absolute;
  top: 0;
  width: 20px;
  height: 100%;
  background: #eff2f5;
  opacity: 0.9;
  z-index: 1;
}
.news-reader__gallery .tns-ovh::before {
  right: 100%;
}
.news-reader__gallery .tns-ovh::after {
  left: 100%;
}
.news-reader__gallery .tns-horizontal {
  display: flex;
}
.news-reader__gallery figure {
  background: url("../../images/news.png") center center/cover no-repeat;
}
.news-reader__gallery img {
  width: auto;
  max-width: none;
  height: 180px;
}
.news-reader__gallery-download {
  display: block;
  margin: 15px 0;
  padding-left: 30px;
  font-size: 16px;
  background: url("../../images/download.svg") 0 4px/18px no-repeat;
}
.news-reader__footer {
  margin-bottom: 75px;
  padding: 40px 20px 20px;
  background: #e6e7e9;
}
.news-reader__footer h3 {
  font-weight: 600;
}
.news-reader__contact {
  margin-bottom: 25px;
}
.news-reader__contact-headline {
  margin-top: 0;
}
.news-reader__contact p {
  margin: 0;
}
.news-reader__contact a {
  color: #92bce8;
  font-weight: 600;
}
.news-reader__contact a:hover {
  text-decoration: underline;
}

@media screen and (min-width: 768px) {
  .news-reader {
    margin-bottom: 100px;
  }
  .news-reader__headline {
    hyphens: manual;
  }
  .news-reader__downloads {
    justify-content: center;
    gap: 90px;
  }
  .news-reader__download {
    padding: 10px;
    background-position: 0 50%;
    font-size: 16px;
  }
  .news-reader__download--pdf {
    padding-left: 40px;
    background-size: 20px auto;
  }
  .news-reader__download--images {
    padding-left: 50px;
    background-size: 30px auto;
  }
  .news-reader__main {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 100px;
  }
  .news-reader__footer {
    padding: 60px 0 40px;
    max-width: 970px;
    margin-left: auto;
    margin-right: auto;
  }
  .news-reader__contacts {
    display: flex;
    flex-wrap: wrap;
    max-width: 800px;
    margin: 0 auto;
  }
  .news-reader__contact {
    width: 50%;
  }
  .news-reader__contact-headline {
    width: 100%;
  }
  .news-reader__contact a {
    font-size: 16px;
  }
  .news-reader__files {
    font-size: 16px;
  }
  .news-reader__gallery img {
    height: 360px;
  }
  .news-reader__gallery figcaption {
    display: none;
  }
  .news-reader__gallery .tns-ovh::before, .news-reader__gallery .tns-ovh::after {
    width: 15px;
  }
  .news-reader__gallery .tns-nav {
    position: relative;
    z-index: 10;
    width: 300px;
    margin: 30px auto 0;
  }
  .news-reader__gallery .tns-controls {
    position: relative;
    z-index: 8;
    top: -50px;
    justify-content: space-between;
    width: 430px;
    margin: 0 auto;
  }
}
@media screen and (min-width: 1200px) {
  .news-reader__gallery .tns-ovh::before, .news-reader__gallery .tns-ovh::after {
    width: 999em;
  }
}
.news-home {
  position: relative;
}
.news-home .tns-ovh {
  overflow: visible !important;
}
.news-home__controls {
  display: flex;
  align-items: flex-end;
  margin-bottom: 35px;
}
.news-home__controls h2 {
  margin-bottom: 0;
}
.news-home__items {
  margin: 0 -20px;
  padding-left: 20px;
}
.news-home__item {
  position: relative;
}
.news-home__item-poster {
  background: url("../../images/news.png") center center/cover no-repeat;
}
.news-home__item-poster img {
  width: 100%;
}
.news-home__item-content {
  position: absolute;
  inset: 0 25px 0 0;
  display: flex;
  flex-direction: column;
  color: #fff;
  letter-spacing: 0.05em;
  background: rgba(0, 0, 0, 0.3);
  pointer-events: none;
}
.news-home__item-content > * {
  pointer-events: all;
}
.news-home__item-headline {
  margin: 0;
  padding: 25px;
  font-size: 18px;
  line-height: 1.3;
  font-weight: 600;
  text-transform: uppercase;
  flex-grow: 1;
}
.news-home__item-client {
  display: block;
  padding: 0 25px;
  font-weight: 700;
}
.news-home__item-date {
  display: block;
  padding: 0 25px 25px;
}

@media screen and (min-width: 768px) {
  .news-home {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }
  .news-home > * {
    padding-left: 15px;
    padding-right: 15px;
  }
  .news-home .tns-ovh {
    overflow: hidden !important;
  }
  .news-home__header {
    padding-left: 15px;
    padding-right: 15px;
    width: 25%;
    flex-shrink: 0;
    align-self: center;
  }
  .news-home__controls {
    flex-direction: column;
  }
  .news-home__controls h2 {
    margin-bottom: 10px;
  }
  .news-home__main {
    padding-left: 15px;
    padding-right: 15px;
    width: 75%;
  }
  .news-home__item-headline {
    font-size: 24px;
  }
  .news-home__item-client, .news-home__item-date {
    font-size: 18px;
  }
}
.mod_article.newsroom {
  margin-bottom: -50px;
}
.mod_article.newsroom + .mod_article {
  padding-top: 50px;
}
@media screen and (min-width: 768px) {
  .mod_article.newsroom {
    margin-bottom: -70px;
  }
  .mod_article.newsroom + .mod_article {
    padding-top: 70px;
  }
}

.news-clients__filters {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  margin: 0 -15px 30px;
  padding: 30px 35px;
}
.news-clients__filters:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 15px;
  right: 15px;
  background: #e6e7e9;
}
.news-clients__filters button {
  width: 100%;
  text-align: start;
  background: none;
  border: none;
  cursor: pointer;
}
.news-clients__filters button.current {
  height: 40px;
  padding: 0 20px;
  overflow: hidden;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: #eff2f5 url("../../images/dropdown.svg") 95% 50%/15px 8px no-repeat;
}
.news-clients__filter {
  position: relative;
  font-size: 14px;
}
.news-clients__filter > ul {
  position: absolute;
  width: 100%;
  top: 40px;
  padding-bottom: 10px;
  background: #fff;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.1s ease-in-out;
}
.news-clients__filter > ul.active {
  opacity: 1;
  pointer-events: all;
  z-index: 10;
}
.news-clients__filter > ul a {
  display: block;
  padding: 6px 20px;
}
.news-clients__filter > ul a:hover {
  color: #92bce8;
}
.news-clients__search input {
  width: 100%;
  height: 40px;
  padding: 0 20px;
  font-size: 14px;
  line-height: 40px;
  font-weight: 600;
  background: #eff2f5 url("../../images/search.svg") 95% 50%/15px 15px no-repeat;
  border: none;
}
.news-clients__search input:focus {
  background-color: #fff;
}
.news-clients__search input::placeholder {
  font-weight: 300;
  font-style: italic;
}
.news-clients__filters--clients-active .news-clients__filter--clients button.current {
  background: #fff;
}
.news-clients__filters--clients-active .news-clients__filter--categories button.current,
.news-clients__filters--clients-active .news-clients__filter--categories input,
.news-clients__filters--clients-active .news-clients__search button.current,
.news-clients__filters--clients-active .news-clients__search input {
  color: #c9cacc;
  background: #e8e9eb;
}
.news-clients__filters--categories-active .news-clients__filter--categories button.current {
  background: #fff;
}
.news-clients__filters--categories-active .news-clients__filter--clients button.current,
.news-clients__filters--categories-active .news-clients__filter--clients input,
.news-clients__filters--categories-active .news-clients__search button.current,
.news-clients__filters--categories-active .news-clients__search input {
  color: #c9cacc;
  background: #e8e9eb;
}
.news-clients__empty {
  flex-grow: 1;
  margin-bottom: 70px;
  text-align: center;
}
.news-clients__item {
  margin: 0 0 70px;
  line-height: 1.25;
}
.news-clients__item-poster {
  background: url("../../images/news.png") center center/cover no-repeat;
}
.news-clients__item-poster img {
  width: 100%;
}
.news-clients__item-headline {
  margin: 0;
}
.news-clients__item-headline a {
  display: block;
  padding: 1em;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.08em;
  text-align: center;
  hyphens: auto;
  background: #fff;
}
.news-clients__item-client {
  display: block;
  margin: 0.25em 0;
  font-weight: 700;
  letter-spacing: 0.05em;
}
.news-clients__item-date {
  display: block;
  margin: 0.25em 0;
  letter-spacing: 0.05em;
}

@media screen and (min-width: 768px) {
  .news-clients {
    margin-bottom: 100px;
  }
  .news-clients__filters {
    flex-direction: row;
    gap: 0;
    position: relative;
    padding-left: 0;
    padding-right: 0;
  }
  .news-clients__filter {
    padding-left: 15px;
    padding-right: 15px;
    width: 33.3333333333%;
    margin-left: 16.6666666667%;
  }
  .news-clients__filter > ul {
    width: calc(100% - 30px);
  }
  .news-clients__search {
    padding-left: 15px;
    padding-right: 15px;
    width: 33.3333333333%;
  }
  .news-clients__items {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }
  .news-clients__items > * {
    padding-left: 15px;
    padding-right: 15px;
  }
  .news-clients__item {
    padding-left: 15px;
    padding-right: 15px;
    width: 33.3333333333%;
  }
}
.news-client__headline {
  hyphens: auto;
}
.news-client__contact {
  margin-top: 25px;
}
.news-client__contact-headline {
  margin-top: 0;
}
.news-client__contact p {
  margin: 0;
}
.news-client__contact a {
  color: #92bce8;
  font-weight: 600;
}
.news-client__contact a:hover {
  text-decoration: underline;
}
.news-client__cols {
  margin-bottom: 75px;
}
.news-client__articles-headline, .news-client__gallery {
  margin: 75px 0 2em;
}
.news-client__gallery {
  font-size: 14px;
}
.news-client__gallery-items {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 1em;
}
.news-client__gallery-items > * {
  padding-left: 15px;
  padding-right: 15px;
}
.news-client__gallery-item {
  margin-bottom: 15px;
}
.news-client__gallery-download {
  display: block;
  margin: 15px 0;
  padding-left: 30px;
  background: url("../../images/download.svg") 0 0/18px no-repeat;
}
.news-client__gallery img {
  width: 100%;
}
.news-client__gallery figcaption {
  display: none;
}

@media screen and (min-width: 480px) {
  .news-client__gallery-item {
    padding-left: 15px;
    padding-right: 15px;
    width: 50%;
  }
}
@media screen and (min-width: 768px) {
  .news-client__headline {
    hyphens: manual;
  }
  .news-client__cols {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }
  .news-client__cols > * {
    padding-left: 15px;
    padding-right: 15px;
  }
  .news-client__main {
    padding-left: 15px;
    padding-right: 15px;
    width: 50%;
    padding-right: 50px;
    hyphens: auto;
  }
  .news-client__gallery {
    margin-top: 0;
    padding-left: 15px;
    padding-right: 15px;
    width: 50%;
  }
  .news-client__articles-headline {
    display: flex;
    justify-content: center;
  }
}