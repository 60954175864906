
@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Thin'), local('Montserrat-Thin'),
    url('../fonts/Montserrat-Thin.woff2') format('woff2'),
    url('../fonts/Montserrat-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Thin Italic'), local('Montserrat-ThinItalic'),
    url('../fonts/Montserrat-ThinItalic.woff2') format('woff2'),
    url('../fonts/Montserrat-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'),
    url('../fonts/Montserrat-ExtraLight.woff2') format('woff2'),
    url('../fonts/Montserrat-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat ExtraLight Italic'), local('Montserrat-ExtraLightItalic'),
    url('../fonts/Montserrat-ExtraLightItalic.woff2') format('woff2'),
    url('../fonts/Montserrat-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Light'), local('Montserrat-Light'),
        url('../fonts/Montserrat-Light.woff2') format('woff2'),
        url('../fonts/Montserrat-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'),
    url('../fonts/Montserrat-LightItalic.woff2') format('woff2'),
    url('../fonts/Montserrat-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url('../fonts/Montserrat-Regular.woff2') format('woff2'),
    url('../fonts/Montserrat-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Italic'), local('Montserrat-Italic'),
    url('../fonts/Montserrat-Italic.woff2') format('woff2'),
    url('../fonts/Montserrat-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Medium'), local('Montserrat-Medium'),
    url('../fonts/Montserrat-Medium.woff2') format('woff2'),
    url('../fonts/Montserrat-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Medium Italic'), local('Montserrat-MediumItalic'),
    url('../fonts/Montserrat-MediumItalic.woff2') format('woff2'),
    url('../fonts/Montserrat-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat SemiBold Italic'), local('Montserrat-SemiBoldItalic'),
    url('../fonts/Montserrat-SemiBoldItalic.woff2') format('woff2'),
    url('../fonts/Montserrat-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Bold'), local('Montserrat-Bold'),
    url('../fonts/Montserrat-Bold.woff2') format('woff2'),
    url('../fonts/Montserrat-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'),
    url('../fonts/Montserrat-BoldItalic.woff2') format('woff2'),
    url('../fonts/Montserrat-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
    url('../fonts/Montserrat-ExtraBold.woff2') format('woff2'),
    url('../fonts/Montserrat-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat ExtraBold Italic'), local('Montserrat-ExtraBoldItalic'),
        url('../fonts/Montserrat-ExtraBoldItalic.woff2') format('woff2'),
        url('../fonts/Montserrat-ExtraBoldItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Black'), local('Montserrat-Black'),
    url('../fonts/Montserrat-Black.woff2') format('woff2'),
    url('../fonts/Montserrat-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Black Italic'), local('Montserrat-BlackItalic'),
        url('../fonts/Montserrat-BlackItalic.woff2') format('woff2'),
        url('../fonts/Montserrat-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
        url('../fonts/Montserrat-SemiBold.woff2') format('woff2'),
        url('../fonts/Montserrat-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Ashby Book';
    src: local('Ashby Book'), local('AshbyBook'),
        url('../fonts/AshbyBook.woff2') format('woff2'),
        url('../fonts/AshbyBook.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Ashby Book';
    src: local('Ashby Book Italic'), local('AshbyBook-Italic'),
        url('../fonts/AshbyBook-Italic.woff2') format('woff2'),
        url('../fonts/AshbyBook-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Ashby';
    src: local('Ashby Medium'), local('AshbyMedium'),
        url('../fonts/AshbyMedium.woff2') format('woff2'),
        url('../fonts/AshbyMedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Ashby';
    src: local('Ashby Bold Italic'), local('Ashby-BoldItalic'),
        url('../fonts/Ashby-BoldItalic.woff2') format('woff2'),
        url('../fonts/Ashby-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Ashby';
    src: local('Ashby Black'), local('AshbyBlack'),
        url('../fonts/AshbyBlack.woff2') format('woff2'),
        url('../fonts/AshbyBlack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Ashby Extra';
    src: local('Ashby Extra Bold Italic'), local('AshbyExtraBold-Italic'),
        url('../fonts/AshbyExtraBold-Italic.woff2') format('woff2'),
        url('../fonts/AshbyExtraBold-Italic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Ashby';
    src: local('Ashby Medium Italic'), local('AshbyMedium-Italic'),
        url('../fonts/AshbyMedium-Italic.woff2') format('woff2'),
        url('../fonts/AshbyMedium-Italic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Ashby';
    src: local('Ashby Light'), local('AshbyLight'),
        url('../fonts/AshbyLight.woff2') format('woff2'),
        url('../fonts/AshbyLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Ashby';
    src: local('Ashby Black Italic'), local('AshbyBlack-Italic'),
        url('../fonts/AshbyBlack-Italic.woff2') format('woff2'),
        url('../fonts/AshbyBlack-Italic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Ashby Extra';
    src: local('Ashby Extra Bold'), local('AshbyExtraBold'),
        url('../fonts/AshbyExtraBold.woff2') format('woff2'),
        url('../fonts/AshbyExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Ashby';
    src: local('Ashby Light Italic'), local('AshbyLight-Italic'),
        url('../fonts/AshbyLight-Italic.woff2') format('woff2'),
        url('../fonts/AshbyLight-Italic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Ashby';
    src: local('Ashby Bold'), local('Ashby-Bold'),
        url('../fonts/Ashby-Bold.woff2') format('woff2'),
        url('../fonts/Ashby-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Tinos';
    src: local('Tinos Regular'), local('Tinos-Regular'),
    url('../fonts/Tinos-Regular.woff2') format('woff2'),
    url('../fonts/Tinos-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Tinos';
    src: local('Tinos Italic'), local('Tinos-Italic'),
    url('../fonts/Tinos-Italic.woff2') format('woff2'),
    url('../fonts/Tinos-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Tinos';
    src: local('Tinos Bold'), local('Tinos-Bold'),
    url('../fonts/Tinos-Bold.woff2') format('woff2'),
    url('../fonts/Tinos-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Tinos';
    src: local('Tinos Bold Italic'), local('Tinos-BoldItalic'),
    url('../fonts/Tinos-BoldItalic.woff2') format('woff2'),
    url('../fonts/Tinos-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}
