$this: '.content-intro';

#{$this} {
    padding: 75px 0;
    text-align: center;

    + .share {
        margin-top: -30px;
    }

    &__dateline {
        @extend .dateline;
    }

    &__headline {
        margin: 40px 0 20px;
        font-size: 36px;
        font-weight: map-get($font-weight, semibold);
        line-height: 1.2;
        letter-spacing: 0.05em;

        span {
            display: block;
            color: map-get($color, primary);

            &:first-child {
                color: map-get($color, secondary);
            }
        }
    }

    &__text {
        font-size: 15px;

        a {
            text-decoration: underline;
        }

        br {
            display: none;

            @include screen(medium) {
                display: initial;
            }
        }
    }

    &__image {
        margin: 100px -20px 0;

        img {
            width: 100%;
        }
    }
}

#{$this}--main {
    #{$this}__headline {
        font-family: Ashby, sans-serif;
        font-weight: map-get($font-weight, xbold);
        text-transform: uppercase;

        span {
            display: block;
            color: map-get($color, primary);

            &:first-child {
                color: map-get($color, secondary);
            }
        }
    }
}

@include screen(medium, #{$this}) {
    &__headline {
        @include grid-col(10);
        @include grid-offset(1);
        margin-bottom: 50px;
        font-size: 60px;
    }

    &__text {
        @include grid-col(8);
        @include grid-offset(2);
        font-size: 20px;
        line-height: 30px;
    }

    &--main {
        padding-bottom: 200px;

        #{$this}__headline {
            margin-top: 50px;
            margin-bottom: 50px;
            font-size: 80px;
        }
    }

    &__image {
        margin: 200px 0 0;
    }
}
