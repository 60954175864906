$this: '.content-team';

#{$this} {
    margin: 70px 0 20px;

    &__header {
        margin-bottom: 60px;
        text-align: center;
    }

    &__filters {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 75px 0 45px;
        padding: 0;
        list-style: none;

        &-break {
            display: block;
            width: 500%;
        }

        li {
            position: relative;
            padding: 5px 0 5px 40px;

            button {
                line-height: 28px;
                color: map-get($color, primary);
                background: none;
                border: none;
                cursor: pointer;
            }

            &:first-child {
                padding-right: 20px;
            }

            &:nth-child(3n+2) {
                &:before {
                    position: absolute;
                    left: 10px;
                    content: ".";
                    color: map-get($color, primary);
                }
            }

            &:nth-child(1n+2) {
                button {
                    @include hover(accent, 20px);
                }
            }
        }
    }

    &__item {
        margin-bottom: 55px;
        font-size: 14px;
    }

    &__image {
        position: relative;

        img {
            width: 100%;
        }

        p {
            display: flex;
            align-items: center;
            overflow-y: auto;
            position: absolute;
            inset: 0;
            z-index: 10;
            margin: 0;
            padding: 20px 10px;
            background: #26201890;
            line-height: 24px;
            color: #fff;
            opacity: 0;
            transition: opacity .2s ease-in-out;
            cursor: help;
        }

        &.active {
            p {
                opacity: 1;
            }
        }
    }

    &__headline {
        margin: 30px 0 0;
        font-size: 18px;
        font-weight: map-get($font-weight, bold);
    }

    &__title {
        margin: 10px 0;
        text-transform: uppercase;
    }

    a {
        color: map-get($color, primary);
        font-weight: map-get($font-weight, semibold);

        &:hover {
            text-decoration: underline;
        }
    }
}

@include screen(medium, $this) {
    margin: 100px 0 50px;

    &__header {
        width: calc(100% / 12 * 8);
        margin-left: auto;
        margin-right: auto;
    }

    &__filters {
        &-break {
            display: none !important;
        }

        li {
            padding-left: 60px;

            &:nth-child(1n+2) {
                &:before {
                    position: absolute;
                    left: 20px;
                    content: ".";
                    color: map-get($color, primary);
                }
            }
        }
    }

    &__image {
        p {
            padding: 20px 30px;
        }
    }
}
